import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { iconTrash } from 'app/common/utils/icons.utils';
import { IngrediantSearchModalComponent } from './ingrediant-search-modal/ingrediant-search-modal.component';
import { Group, GroupItem, IngrediantService } from 'app/services/ingrediant.service';
import { PopupInfoComponent } from '../popup-info/popup-info.component';

export class ReceiptItem extends GroupItem {
	label;
	quantity;
	measurementUnit;
}

@Component({
	selector: 'receipt-creation',
	templateUrl: './receipt-creation.component.html',
	styleUrls: ['receipt-creation.component.scss']
})
export class ReceiptCreationComponent implements OnInit, AfterViewInit{
	@Input("item") item: GroupItem;

	// public form: FormGroup = null;
	pageLoaded = false;
	iconCancel = iconTrash;
	constructor(private _fb: FormBuilder,
		private _modalService: ModalService,
		private _ingrediantService: IngrediantService
	) {

	}
	// _quantity = 100;
	// get quantity() {
	// 	return this._quantity;
	// }
	// set quantity(v) {
	// 	this._quantity = v;
	// 	this.items.forEach(e => {
	// 		if (Number.isInteger(e.baseValue))
	// 			e.value = <number>e.baseValue * this._quantity / 100;
	// 	})
	// }
	static index = 0;
	classes = ["A", "B", "C", "D", "E"];

	fishClass: string=undefined;
	get carbonFootprint() {
		let value = 0;
		this.ingrediants.forEach((e) => {
			value += e.carbonFootprint * e.quantity / 1000;
		})
		return value/this.servingNumber;
	}
	get waterFootprint() {
		let value = 0;
		this.ingrediants.forEach((e) => {
			value += e.waterFootprint * e.quantity / 1000;
		})
		return value/this.servingNumber;
	}
	get fishQualitySustainability() {
		return "A";
	}

	get energy () {
		let value = 0;
		this.ingrediants.forEach((e) => {
			value += e.energy * e.quantity / 100;
		})
		return Math.round((value/this.servingNumber)*100)/100; ;
		
	}

	servingNumber = 1;
	addServing = (n: number) => {
		if (this.servingNumber+n >= 1) this.servingNumber = this.servingNumber+n;
		else this.servingNumber = 1;
		this.updatesValues();
	}
	get servingLabel() {
		if (this.servingNumber == 0) return "N. Servings";
		if (this.servingNumber == 1) return "1 Serving";
		return this.servingNumber + " Servings";
	}
	ngAfterViewInit(): void {
		
	}
	data = [];


	private recursiveGetFoodItems = (group: Group) => {
		if (group.items && group.items.length > 0) {
			group.items.forEach((item) => {
				let viewItem: ReceiptItem = new ReceiptItem();
				Object.assign(viewItem, item);
				viewItem.label = item.id;
				viewItem.quantity = 100;
				viewItem.measurementUnit = (item.unitsWaterfootprint == "liters water/kg")? "g": "ml";
				this.data.push(viewItem);
			});
		}
		if (group.subgroups && group.subgroups.length > 0) {
			group.subgroups.forEach((g) => {
				this.recursiveGetFoodItems(g);
			})
		}
	}

	ngOnInit() {
		this._ingrediantService.getAll().subscribe((result) => {
			// result.forEach((group: Group) => {
			// 	group.subgroups.forEach((sub: Group) => {
			// 		if (sub.items && sub.items.length>0) sub.items.forEach((item) => {
			// 			this.data.push({
			// 				id: item.id, label: item.id, baseValue: 100, value: 100, measurementUnit: "g"
			// 			})
			// 		});
			// 		else 
			// 			this.data.push({
			// 				id: sub.id, label: sub.id, baseValue: 100, value: 100, measurementUnit: "g"
			// 			})
			// 	})

			// })

			result.forEach((g) => {
				this.recursiveGetFoodItems(g);
			})			

			this.pageLoaded = true;


		});

	}

	getItem = (id) => {
		console.log("search " + id);
		if (id) {
			return this.data.filter((item => {
				return item.id == id;
			}))[0];
		}
		
	}
	
	ingrediants: ReceiptItem[] = [];
	addItemRequest = () => {
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {
				console.log("select ", res);
				if (res) {
					
					let found = false;
					this.ingrediants.forEach((el) => {
						if (el.id == res.id) {
							found = true;
						}
					})
					if (!found) {
						let viewItem: ReceiptItem = new ReceiptItem();
						Object.assign(viewItem, res);
						viewItem.label = res.id;
						viewItem.quantity = 100;
						viewItem.measurementUnit = (res.unitsWaterfootprint == "liters water/kg")? "g": "ml";
						this.ingrediants.push(viewItem);
						this.updatesValues()
					}
				}
					
			}
		}
		let data = {
			
		}
		this._modalService.show(IngrediantSearchModalComponent, data, modalOption);
	}

	downloadMessage = "";
	
	
	removeItem = (find) => {
		// if ((<ReceiptItem>find).blueSustainabilityIndex) {
		// 	this.fishClass = undefined;
		// }
		this.ingrediants = this.ingrediants.filter((item) => {
			return item.id != find.id;
		})
		this.updatesValues();
		// console.log("Hello " + id);
		
		// let c = [];
		// this.ingrediants.forEach(element => {
		// 	if (element.id != id)
		// 		c.push(element)	
		// });;
		// console.log("new value",c);
		// this.ingrediants = c;
		// // this.form.setValue({ingrediants: c}, {emitEvent: true});

		
	}
	items = [
	]
	
	baseColors = ["#fab726", "#d82239", "#91c467","#1e8ca8", "#dd8c33", "#50adea"];
	
	legendColor = ["#fab726", "#1e8ca8", "#d82239", "#91c467"];
	legendLabels = ['Proteins', 'Fat', 'Saturated Fat', "Monounsaturated Fat", "Polyunsaturated Fat", "Carbohydrates", "Soluble", "Fiber"];
		
	allData;
	updatesValues = () => {
		if (this.ingrediants.length > 0) {
			let energySeries = [0,0,0,0];
			this.allData = [0,0,0,0,0,0,0,0];
			let series = [];
			let colors = [];
			let labels = [];
			this.ingrediants.forEach((el, index) => {
				energySeries[0] = energySeries[1] + (el.proteins * el.quantity / 100);
				energySeries[1] = energySeries[1] + (el.fat * el.quantity / 100);
				energySeries[2] = energySeries[2] + (el.carbohydrates * el.quantity / 100);
				energySeries[3] = energySeries[3] + (el.fiber * el.quantity / 100);

				this.allData[0] = this.allData[0] + (el.proteins * el.quantity / 100);
				this.allData[1] = this.allData[1] + (el.fat * el.quantity / 100);
				this.allData[2] = this.allData[2] + (el.saturatedFat * el.quantity / 100);
				this.allData[3] = this.allData[3] + (el.monounsaturatedFat * el.quantity / 100);
				this.allData[4] = this.allData[4] + (el.polyunsaturatedFat * el.quantity / 100);
				this.allData[5] = this.allData[5] + (el.carbohydrates * el.quantity / 100);
				this.allData[6] = this.allData[6] + (el.soluble * el.quantity / 100);
				this.allData[7] = this.allData[7] + (el.fiber * el.quantity / 100);
				
				series.push(Math.round(((el.carbonFootprint * el.quantity / 1000)/this.servingNumber) * 100)/100);
				colors.push(this.baseColors[index%this.baseColors.length]);
				labels.push(el.label);
			})
			for (let i=0; i< energySeries.length; i++) energySeries[i]= Math.round((energySeries[i]/this.servingNumber)*100)/100;
			for (let i=0; i< this.allData.length; i++) this.allData[i]= Math.round((this.allData[i]/this.servingNumber)*100)/100;
			
			
			this.pieChartOptions.series = series;
			this.pieChartOptions.colors = colors;
			this.pieChartOptions.labels = labels;
			// let tmp = this.donutChartOptions;
			// this.donutChartOptions = null;
			// (<any>this.donutChartOptions) = new Object()
			// Object.assign(this.donutChartOptions,tmp);

			this.donutChartOptions.series =energySeries;
			let plotOptions: any = new Object();
			Object.assign(plotOptions, this.donutChartOptions.plotOptions);
			plotOptions.pie.donut.labels.total.label = `${this.energy} Kcal`;
			
			this.donutChartOptions.plotOptions = plotOptions;

			console.log(`${this.energy} Kcal`);
			
		}
			
		else {
			// this.donutChartOptions.series = this.radialBarChartOptions.series = [0, 0, 0, 0, 0,0];
			this.donutChartOptions.series = [0,0,0,0,0,0,0,0];

			let series = [];
			let colors = [];
			let labels = [];
			
			// this.radialBarChartOptions.series = series;
			// this.radialBarChartOptions.colors = colors;
			// this.radialBarChartOptions.labels = labels;

			// this.barChartOptions.series = [{
			// 	data: series
			// }]
			// this.barChartOptions.series = series;
			// this.barChartOptions.colors = colors;
			// this.barChartOptions.xaxis.categories = labels;

			this.pieChartOptions.series = series;
			this.pieChartOptions.colors = colors;
			this.pieChartOptions.labels = labels;
		}
	}

	// radialBarChartOptions = {
	// 	chart: {
	// 	  height: 350,
	// 	  type: 'radialBar',
	// 	},
	// 	colors: [],
	// 	stroke: {
	// 		lineCap: "round"
	// 	  },
	// 	plotOptions: {
	// 	  radialBar: {
			
	// 		dataLabels: {
	// 		  name: {
	// 			fontSize: '22px',
	// 		  },
	// 		  value: {
	// 			fontSize: '16px',
	// 			formatter: function (val, x) {
	// 				console.log("X: ", x, val)
	// 				return Math.floor(val) + 'g'
	// 			}
	// 		  },
	// 		  total: {
	// 			show: true,
	// 			label: 'Total',
	// 			// color: $label_color,
	// 			formatter: function (w) {
	// 			  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
	// 			  return '249'
	// 			}
	// 		  }
	// 		}
	// 	  }
	// 	},
	// 	series: [],
	// 	labels: [],
	// }

	donutChartOptionsFormatter = (w) => {
		// console.log("formatter ", w);
		
		// return  `${this.energy} Kcal`;
	}

	donutChartOptions = {
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: true,
						name: {
							offsetY: 10,
						},                        
						total: {
							show: true,
							showAlways: true,
							fontSize: '22px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							fontWeight: 600,
							color: "#1a8ca7",
							label: `${this.energy} Kcal`,
							// formatter: this.donutChartOptionsFormatter
							formatter: function (w) {
								return ""
							}
						}
					}
				}
			}
		}, 
		chart: {
		  type: 'donut',
		  height: 320
		},
		colors: ["#fab726", "#1e8ca8", "#d82239", "#91c467"],
		labels: ['Proteins', 'Fat', "Carbohydrates", "Fiber"],
		series: [0,0,0,0],
		
		legend: {
			show: false
		},
		dataLabels: {
			enabled: true,
			formatter: function (val) {
				return Math.floor(val) + '%'
			},
			total: {
				show: true,
				label: 'Total',
				// color: $label_color,
				formatter: function (w) {
				  // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
				  return '249'
				}
			  }
		  },
		responsive: [{
		  breakpoint: 576,
		  options: {
			chart: {
			  width: 300
			},
			legend: {
			  position: 'bottom'
			}
		  }
		}],

		// fill: {
		// 	type: 'image',
		// 	image: {
		// 	  src: ['/path/to/image1.png', 'path/to/image2.jpg'],
		// 	  width: undefined,  // optional
		// 	  height: undefined  //optional
		// 	}
		//   }
	}

	
// 	doughnutChartLabels: string[] = ["January", "February", "March", "April", "May"];
// 	doughnutChartData = [
// 		{ data: [65, 59, 80, 81, 56], label: 'Series A' },
//   { data: [40, 19, 86, 27, 78], label: 'Series B' },
// 	] 
	
// 	// [65, 35, 24, 45, 85];
// 	doughnutChartColors: any[] = [{ backgroundColor: ['#975AFF', '#F77E17', '#40C057', '#F55252', '#2F8BE6'] }];
// 	doughnutChartType = 'doughnut';
// 	doughnutChartOptions: any = {
// 		animation: false,
// 		responsive: true,
// 		maintainAspectRatio: false
// 	};

	// barChartOptions = {
	// 	chart: {
	// 	//   height: 350,
	// 	//   type: 'bar',
	// 	  type: 'pie',
	// 	},
	// 	colors: ["#50adea","#50adea","#50adea","#50adea","#50adea"],
	// 	plotOptions: {
	// 	  bar: {
	// 		horizontal: true,
	// 	  }
	// 	},
	// 	dataLabels: {
	// 	  enabled: false
	// 	},
	// 	series: [],
	// 	xaxis: {
	// 	  categories: [],
	// 	  tickAmount: 2
	// 	}
	// }

	pieChartOptions = {
		chart: {
		  type: 'pie',
		  height: 320
		},
		colors: [],
		labels: [],
		series: [],
		legend: {
		 show: false
		},
		responsive: [{
		  breakpoint: 576,
		  options: {
			chart: {
			  width: 300
			},
			legend: {
			  position: 'bottom'
			}
		  }
		}]
	  }

	get hasMoreIngredients() {
		return this.ingrediants && this.ingrediants.length>0
	}


	downloadMsgShotMessage = "";

	get fishScoreClass() {
		let counter = 0;
		let presentLow = false;
		let presentModerate = false;
		let presentOptimal = false;

		this.ingrediants.forEach((element: ReceiptItem) => {
			if (element.blueSustainabilityIndex == "LIMIT") {
				presentLow = true;
				counter++;
			}
			else if (element.blueSustainabilityIndex == "OPTIMAL") {
				presentOptimal = true;
				counter++;
			}
			else if (element.blueSustainabilityIndex == "MODERATE" || element.blueSustainabilityIndex == "MODERATE/ LIMIT") {
				presentModerate = true;
				counter++;
			}
		})
		if (presentLow) {
				this.fishClass = (counter > 1)? "limit-color-more-than-1": "limit-color";
		}
		else if (presentModerate) {
			this.fishClass =  (counter > 1)? "moderate-color-more-than-1": "moderate-color";
		}
		else if (presentOptimal) {
			this.fishClass =  (counter > 1)? "optimal-colo-more-than-1r": "optimal-color";
		}
		else {
			this.fishClass = undefined;
		}
		return this.fishClass;
	}

	private ceckFishClass() {
		if (this.fishClass) {
			if (this.fishClass.startsWith("limit") ) this.downloadMessage += " Due to high ecological impacts, suggested consumption of this fish is 1 or less per week, alternate consumption with other fish species and/or use certified fish for sustainability whenever possible.";
			else if (this.fishClass.startsWith("moderate") ) this.downloadMessage += " To increase the sustainability of your meals alternate fish species in the week and use certified fish for sustainability whenever possible.";
		}
	}
	get envScoreClass() {
		let result;
		if (this.carbonFootprint <= 0.2) {
			this.downloadMsgShotMessage = "Very Good!";
			this.downloadMessage = "Low impact on climate, you could combine this dish with other green (B), yellow (C) and orange (D) and red (E) banding dishes.";
			result = "A";
		}
		if (this.carbonFootprint > 0.2 && this.carbonFootprint <=0.5 ) {
			this.downloadMsgShotMessage = "Very Good!";
			this.downloadMessage = "Low impact on climate, you could combine this dish with other green (A), yellow (C) and orange (D) and red (E) banding dishes.";
			result = "B";
		}
		if (this.carbonFootprint > 0.5 && this.carbonFootprint <=1 ) {
			this.downloadMsgShotMessage = "Good!";
			this.downloadMessage = "Moderate impact on climate, to stay within sustainable meal limits combine this dish with green banding (A, B) dishes.";
			result = "C";
		}
		if (this.carbonFootprint > 1 && this.carbonFootprint <=1.2 ) {
			this.downloadMsgShotMessage = "Attention!";
			this.downloadMessage = "High impact on climate, to stay within climatic impact limit the consumption frequency and combine this dish only with green banding (A, B) dishes in your meal.";
			result = "D";
		}
		if (this.carbonFootprint > 1.2) {
			this.downloadMsgShotMessage = "Attention!";
			this.downloadMessage = "Very high impact on climate, limit the consumption frequency and combine this dish only with green banding (A, B) dishes in your meal.";
			result = "E";
		}
		this.ceckFishClass();
		return result;

		// if (this.carbonFootprint <= 0.2) {
		// 	this.downloadMsgShotMessage = "Very Good!";
		// 	if (this.fishClass)
		// 		this.downloadMessage = "You can eat it at every meal and combined with other green (B), yellow (C) and orange (D) banding dishes.";
		// 	else	
		// 		this.downloadMessage = "You can eat it at every meal and combined with other green (B), yellow (C) and orange (D) banding dishes.";
		// 	return "A";
		// }
		// if (this.carbonFootprint > 0.2 && this.carbonFootprint <=0.5 ) {
		// 	this.downloadMsgShotMessage = "Very Good!";

		// 	if (this.fishClass)
		// 		this.downloadMessage = "You can eat it at every meal and combined with other green (A), yellow (C) and orange (D) banding dishes.";
		// 	else	
		// 		this.downloadMessage = "You can eat it at every meal and combined with other green (A), yellow (C) and orange (D) banding dishes";
		// 	return "B";
		// }
		// if (this.carbonFootprint > 0.5 && this.carbonFootprint <=1 ) {
		// 	this.downloadMsgShotMessage = "Good!";
		// 	if (this.fishClass)
		// 		this.downloadMessage = "To stay within sustainable meal limits, combine this dish with green banding (A, B) dishes. Although you can consume this fish 2/3 times per week it is always good to alternate consumption with other species and use certified fish for sustainability whenever possible.";
		// 	else	
		// 		this.downloadMessage = "To stay within sustainable meal limits, combine this dish with green banding (A, B) dishes.";
		// 	return "C";
		// }
		// if (this.carbonFootprint > 1 && this.carbonFootprint <=1.2 ) {
		// 	this.downloadMsgShotMessage = "Attention!";
		// 	if (this.fishClass)
		// 		this.downloadMessage = "Limit consumption to 1 per week, alternate consumption with other species, use certified fish for sustainability whenever possible. Combine this dish with green banding (A, B) dishes in your meal.";
		// 	else	
		// 		this.downloadMessage = "Limit consumption frequency to 1-2 per week and combine only with green banding (A, B) dishes in your meal.";
		// 	return "D";
		// }
		// if (this.carbonFootprint > 1.2) {
		// 	this.downloadMsgShotMessage = "Attention!";
		// 	if (this.fishClass)
		// 		this.downloadMessage = "Limit consumption to 1 or less per week, alternate consumption with other species, use certified fish for sustainability whenever possible. Combine this dish with green banding (A, B) dishes in your meal.";
		// 	else	
		// 		this.downloadMessage = "Limit consumption frequency to 1 per week and combine only with green banding (A, B) dishes in your meal.";
		// 	return "E";
		// }
	} 

	// () {
	// 	let env = this.envScoreClass;
	// 	console.log("env ", env);
		
	// 	switch (env) {
	// 		case "A": 
	// 		case "B": 
	// 		case "C": 
	// 		case "D": 
	// 		case "E": 
	// 		default: return "nessuno";
	// 	}
	// }

	openLearnMore = () => {
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {}
		}
		let data = {
			sectionId: "RECIPE"
		}
		this._modalService.show(PopupInfoComponent, data, modalOption);
	}

	openFishIndicator = () => {
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {}
		}
		
		let data = {
			sectionId: this.fishClass
		}
		
		this._modalService.show(PopupInfoComponent, data, modalOption);
	}
}