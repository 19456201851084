import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Gtag } from 'angular-gtag';



@Component({
	selector: 'about-page',
	templateUrl: './about.page.html',
	styleUrls: ['about.page.scss']
})
export class AboutPage implements OnInit, AfterViewInit{
	companyId: string;
	certificate: any;
	
	constructor(
		private _router: Router,private gtag: Gtag
	) {
		
	}


	ngAfterViewInit(): void {
		
	}

	ngOnInit() {
		this.gtag.event('about-page', {
			event_category: "Page view",
			'app_name': "switch-food-explorer",
			'screen_name': 'about-page'
		});

		// this.gtag.pageview({
		// 	page_title: "About Page",
		// 	page_path: "/about-page",
			
		// 	// 'app_name': "switch-food-explorer",
		// 	// 'screen_name': 'about-page'
		// });
	}
	goToSection = (section) => {
		const queryParams = {};
		this._router.navigate([section], { queryParams: queryParams });
	}

	openSite = () => {
		window.open("https://switchdiet.eu/about-switch-project/", "_new");
	}
	
}