export const conversionTable = [
    {
        "foodvisorItem": "Stevia",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Baked Cod",
        "foodItem": "COD (F)"
    },
    {
        "foodvisorItem": "Mayonnaise",
        "foodItem": "MAYONNAISE"
    },
    {
        "foodvisorItem": "Maroilles",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Savory cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Custom salad",
        "foodItem": "LEAFY VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Blackberries",
        "foodItem": "BLACKBERRY"
    },
    {
        "foodvisorItem": "Individual bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Sucrine",
        "foodItem": "LETTUCE"
    },
    {
        "foodvisorItem": "Fruit cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Quince",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Oat flour",
        "foodItem": "OATMEAL FLOUR"
    },
    {
        "foodvisorItem": "Cooked red cabbage",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Bocconcini",
        "foodItem": "MOZZARELLA"
    },
    {
        "foodvisorItem": "Salmon sashimi",
        "foodItem": "SALMON (F)"
    },
    {
        "foodvisorItem": "Avocado oil",
        "foodItem": "NUTS AND SEEDS OIL"
    },
    {
        "foodvisorItem": "Kiri",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Crepe with Nutella",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Oatmeal (water)",
        "foodItem": "OATMEAL FLAKES"
    },
    {
        "foodvisorItem": "Soluble coffee",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Chocolat liégeois",
        "foodItem": "MILK CHOCOLATE"
    },
    {
        "foodvisorItem": "Red Bull 35cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Chicken nuggets",
        "foodItem": "Poultry&Rabbit (General)"
    },
    {
        "foodvisorItem": "Candy",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Whey protein powder vanilla",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Croque-monsieur",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Mixed greens",
        "foodItem": "LEAFY VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Herbal tea",
        "foodItem": "TEA"
    },
    {
        "foodvisorItem": "Basmati rice",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Dim sum",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Scampi (with shell)",
        "foodItem": "SHRIMP (F)"
    },
    {
        "foodvisorItem": "Pâté en croûte",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Pasta",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Marshmallow",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Bobun",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Chocolate cereal bar",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Semi-salted butter (40% Fat)",
        "foodItem": "BUTTER"
    },
    {
        "foodvisorItem": "Pringles",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Snails",
        "foodItem": "SNAILS"
    },
    {
        "foodvisorItem": "Red lentils",
        "foodItem": "LENTIL (d)"
    },
    {
        "foodvisorItem": "Hollandaise sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Porridge with water",
        "foodItem": "OATMEAL FLAKES"
    },
    {
        "foodvisorItem": "Night Pro",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Lettuce",
        "foodItem": "LETTUCE"
    },
    {
        "foodvisorItem": "Dutch cheese",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Beef flank steak",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Cupcake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Broad beans",
        "foodItem": "FABA BEAN (f)"
    },
    {
        "foodvisorItem": "Cashew nuts",
        "foodItem": "CASHEW NUT"
    },
    {
        "foodvisorItem": "Cake Line",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Avocado toast",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Clam Juice",
        "foodItem": "CLAM (F)"
    },
    {
        "foodvisorItem": "Sole meunière",
        "foodItem": "SOLE (W)"
    },
    {
        "foodvisorItem": "California tuna roll",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Hamburger bun",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Pink Lady Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Nesquik",
        "foodItem": "CHOCOLATE"
    },
    {
        "foodvisorItem": "McDonald's Fries",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Chicken Tabbouleh",
        "foodItem": "BULGUR"
    },
    {
        "foodvisorItem": "Andouillette",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Muffin",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Red Plum",
        "foodItem": "PLUM"
    },
    {
        "foodvisorItem": "Gruyère cheese",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Cooked pearl barley",
        "foodItem": "BARLEY"
    },
    {
        "foodvisorItem": "Muesli",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Bulgur",
        "foodItem": "BULGUR"
    },
    {
        "foodvisorItem": "Grapefruit juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Romaine lettuce",
        "foodItem": "ROMAIN LETTUCE"
    },
    {
        "foodvisorItem": "Nectarine",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Strawberry ice cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Cracker appetizers",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Grilled onions",
        "foodItem": "ONION"
    },
    {
        "foodvisorItem": "Coffee lungo",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Shortbread",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Fresh zucchinis",
        "foodItem": "ZUCCHINI"
    },
    {
        "foodvisorItem": "Walnuts (in shell)",
        "foodItem": "WALNUT"
    },
    {
        "foodvisorItem": "Fresh green beans",
        "foodItem": "GREEN BEAN"
    },
    {
        "foodvisorItem": "Einkorn whole wheat flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Iced tea",
        "foodItem": "TEA"
    },
    {
        "foodvisorItem": "Walnut tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Saint-Agur",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Toast with honey",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Scallops",
        "foodItem": "SCALLOP (F)"
    },
    {
        "foodvisorItem": "Candy apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Sugar free hemp drink",
        "foodItem": "PLANT BASED MILK (Generic)"
    },
    {
        "foodvisorItem": "Diced mixed vegetables",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Coffee with milk",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Soy milk",
        "foodItem": "SOY MILK"
    },
    {
        "foodvisorItem": "Raw Cauliflower",
        "foodItem": "CAULIFLOWER"
    },
    {
        "foodvisorItem": "Green salad",
        "foodItem": "LEAFY VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Cauliflower gratin",
        "foodItem": "CAULIFLOWER"
    },
    {
        "foodvisorItem": "Prince Cookies",
        "foodItem": "SIMPLE COOKIES"
    },
    {
        "foodvisorItem": "Wings",
        "foodItem": "Poultry&Rabbit (General)"
    },
    {
        "foodvisorItem": "Teriyaki chicken",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Mashed vegetables",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Coca-Cola Zero 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Wheat",
        "foodItem": "WHEAT"
    },
    {
        "foodvisorItem": "California roll",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Filled waffle",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Bagel sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Roast veal",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Faggots",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Golden Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Beef bourguignon",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Oat bran",
        "foodItem": "OATMEAL FLOUR"
    },
    {
        "foodvisorItem": "Pigeon",
        "foodItem": "Poultry&Rabbit (General)"
    },
    {
        "foodvisorItem": "Cereals",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Béarnaise sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Orange flavored biscuit",
        "foodItem": "SIMPLE COOKIES"
    },
    {
        "foodvisorItem": "Teff flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Beef kidneys",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Fish soup",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Pulled Pork Sandwich",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Burrata",
        "foodItem": "MOZZARELLA"
    },
    {
        "foodvisorItem": "Soybean oil",
        "foodItem": "SOYBEAN OIL"
    },
    {
        "foodvisorItem": "Bolognese sauce",
        "foodItem": "TOMATO SAUCE"
    },
    {
        "foodvisorItem": "Schtroumpfs Haribo",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Wasa toast",
        "foodItem": "CRISPBREAD"
    },
    {
        "foodvisorItem": "Maple syrup",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Quenelle",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Brains",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Peas",
        "foodItem": "GREEN BEAN"
    },
    {
        "foodvisorItem": "Cumin",
        "foodItem": "CUMIN"
    },
    {
        "foodvisorItem": "Ice Tea 50cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Mushroom omelette",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Spring roll (fried)",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Vol-au-vent",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Marinated Fish Ceviche",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Stuffed eggplant",
        "foodItem": "EGGPLANT (AUBERGIN)"
    },
    {
        "foodvisorItem": "Clam Chowder",
        "foodItem": "CLAM (F)"
    },
    {
        "foodvisorItem": "Butterscotch",
        "foodItem": "BROWN SUGAR"
    },
    {
        "foodvisorItem": "Cannoli",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Bruschetta",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Tuna tartare",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Guyot Pear",
        "foodItem": "PEAR"
    },
    {
        "foodvisorItem": "Warty venus",
        "foodItem": "CLAM (F)"
    },
    {
        "foodvisorItem": "Morteau sausage",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Pesto pasta",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Grison meat",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Dry green lentils",
        "foodItem": "LENTIL (d)"
    },
    {
        "foodvisorItem": "Kir",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Whole wheat tortilla",
        "foodItem": "BREAD WHOLE"
    },
    {
        "foodvisorItem": "Strawberry tartlet",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Nalu",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Breadcrumbs",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Prawns",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "Decaffeinated coffee",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Canned mackerel in escabeche",
        "foodItem": "MACKEREL (W)"
    },
    {
        "foodvisorItem": "Steamed dumplings",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Heart of beef",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Red lentil flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Chocolate éclair",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Red lentils pasta",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Shelled almonds",
        "foodItem": "ALMOND"
    },
    {
        "foodvisorItem": "Raw pak choy cabbage",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Grand Marnier",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Cooked carrots",
        "foodItem": "CARROT"
    },
    {
        "foodvisorItem": "Gin shot",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Farfalle",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Special K Chocolate",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Vine tomatoes",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Tartiflette",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Cheese pizza slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Edamame",
        "foodItem": "SOYBEAN (d)"
    },
    {
        "foodvisorItem": "Maki cheese",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Cauliflower",
        "foodItem": "CAULIFLOWER"
    },
    {
        "foodvisorItem": "Milk chocolate chips",
        "foodItem": "MILK CHOCOLATE"
    },
    {
        "foodvisorItem": "Smoked mackerel",
        "foodItem": "MACKEREL (W)"
    },
    {
        "foodvisorItem": "Lemon",
        "foodItem": "LEMON"
    },
    {
        "foodvisorItem": "Barbecue sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Pound cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Beef meat",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Pepsi 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Ricotta and spinach ravioli",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Dried parsley",
        "foodItem": "PARSLEY"
    },
    {
        "foodvisorItem": "Plum Pie",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Neufchâtel",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Chipster Belin",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Wild rice",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Red Bull",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Black pudding",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "White sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Hazelnut ice cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Dry salami",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Irish pudding",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Figs",
        "foodItem": "FIG"
    },
    {
        "foodvisorItem": "Pork terrine",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Poultry cordon bleu",
        "foodItem": "Poultry&Rabbit (General)"
    },
    {
        "foodvisorItem": "Samosas",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Chanterelle mushroom",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Chocolate croissant",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Beef Tartare",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Oasis 50cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Provençal tomato tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Saint-Môret Cheese",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Bread/strong flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Comté",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Banana chips",
        "foodItem": "BANANA"
    },
    {
        "foodvisorItem": "Hard boiled egg white",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Cranberry juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Brazil nuts",
        "foodItem": "BRAZIL NUT"
    },
    {
        "foodvisorItem": "Krisprolls Wholegrain",
        "foodItem": "WHOLEGRAIN CRACKERS"
    },
    {
        "foodvisorItem": "Whole raw egg",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Pretzel",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Marinated salmon",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Beef rib steak",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Red wine sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Basic risotto",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "White aspargus",
        "foodItem": "ASPARAGUS"
    },
    {
        "foodvisorItem": "Endive salad",
        "foodItem": "ENDIVE"
    },
    {
        "foodvisorItem": "Mornay sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Margarita",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Gin tonic",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Custom pizza",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fresh peas and carrots",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Saffron rice",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Custom toast",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Frozen eggplant",
        "foodItem": "EGGPLANT (AUBERGIN)"
    },
    {
        "foodvisorItem": "Canned courgette",
        "foodItem": "ZUCCHINI"
    },
    {
        "foodvisorItem": "Plain yogurt cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cachir",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Celeriac with remoulade dressing",
        "foodItem": "CELERIAC"
    },
    {
        "foodvisorItem": "Passion fruit nectar",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Ice Tea Light",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Orangina 50cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Mackerel",
        "foodItem": "MACKEREL (W)"
    },
    {
        "foodvisorItem": "Sushi",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Banana",
        "foodItem": "BANANA"
    },
    {
        "foodvisorItem": "Tuna maki",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Smoked ham",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Cabbage",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Coffee Ice cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Spätzle",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Baked trout",
        "foodItem": "TROUT (F)"
    },
    {
        "foodvisorItem": "Calf sweatbread",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Maredsous",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Peach jam",
        "foodItem": "PEACH"
    },
    {
        "foodvisorItem": "Candy bar",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Aspartame sweetener",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Cheese gougère",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Marinated bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Caïpirinha",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Lamb stew",
        "foodItem": "LAMB"
    },
    {
        "foodvisorItem": "Whole kiwi",
        "foodItem": "KIWI"
    },
    {
        "foodvisorItem": "Beef pie",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Beef carpaccio",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Schweppes Agrum' Zero 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Cocoa powder",
        "foodItem": "COCOA (Powder)"
    },
    {
        "foodvisorItem": "Ground beef (16%)",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Liquid Vanilla",
        "foodItem": "VANILLA"
    },
    {
        "foodvisorItem": "Pizza Regina",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Ferrero Rocher",
        "foodItem": "MILK CHOCOLATE"
    },
    {
        "foodvisorItem": "Whole goat's milk",
        "foodItem": "GOAT MILK"
    },
    {
        "foodvisorItem": "Raw red onion",
        "foodItem": "ONION"
    },
    {
        "foodvisorItem": "Fresh green aspargus",
        "foodItem": "ASPARAGUS"
    },
    {
        "foodvisorItem": "Dark hazelnut chocolate",
        "foodItem": "DARK CHOCOLATE"
    },
    {
        "foodvisorItem": "Tea",
        "foodItem": "TEA"
    },
    {
        "foodvisorItem": "Ham and cheese pizza slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Spiny Lobster",
        "foodItem": "LOBSTER (W)"
    },
    {
        "foodvisorItem": "Salt",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Flaked crab",
        "foodItem": "CRAB (W)"
    },
    {
        "foodvisorItem": "Pork cordon bleu",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Oreo",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Wheat tortilla",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Rice drink",
        "foodItem": "RICE MILK"
    },
    {
        "foodvisorItem": "Duck liver pâté",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Celeriac salad",
        "foodItem": "CELERIAC"
    },
    {
        "foodvisorItem": "Crab cake",
        "foodItem": "CRAB (W)"
    },
    {
        "foodvisorItem": "Jerusalem artichoke",
        "foodItem": "ROOTS AND TUBERS (Generic)"
    },
    {
        "foodvisorItem": "Leek and potato soup",
        "foodItem": "LEEK"
    },
    {
        "foodvisorItem": "Broccoli Purée",
        "foodItem": "BROCCOLI"
    },
    {
        "foodvisorItem": "Small macaroni",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Prawn crackers",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "Mille-feuille",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Veal chop",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Pizza Quattro Stagioni",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fish and chips",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Diet Coke 50cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Fennel salad",
        "foodItem": "FENNEL"
    },
    {
        "foodvisorItem": "Meat pie",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Country bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Twix",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Whole almond puree",
        "foodItem": "ALMOND"
    },
    {
        "foodvisorItem": "Coconut oil",
        "foodItem": "NUTS AND SEEDS OIL"
    },
    {
        "foodvisorItem": "Ground beef (5% Fat)",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Non-Fat Plain Greek Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Raspberry tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cheese risotto",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Krisprolls",
        "foodItem": "CRISPBREAD"
    },
    {
        "foodvisorItem": "Frozen brussel sprouts",
        "foodItem": "BRUSSELS SPROUT"
    },
    {
        "foodvisorItem": "Cuttlefish ink pasta",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Chicken ham",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Pork brisket",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Coffee Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Veal shoulder roast",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Meatloaf",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Corn flour",
        "foodItem": "MAIZE FLOUR"
    },
    {
        "foodvisorItem": "Soda",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Steak",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Chervil",
        "foodItem": "CHEVRIL"
    },
    {
        "foodvisorItem": "Red fruit tea",
        "foodItem": "TEA"
    },
    {
        "foodvisorItem": "Chocolate BN",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Canelé",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Petit Ecolier dark chocolate",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Pomegranate",
        "foodItem": "POMEGRANATE"
    },
    {
        "foodvisorItem": "Cucumber maki",
        "foodItem": "CUCUMBER"
    },
    {
        "foodvisorItem": "Alcohol-free draught beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Chive",
        "foodItem": "CHIVE"
    },
    {
        "foodvisorItem": "Tomato quiche",
        "foodItem": "TOMATO SAUCE"
    },
    {
        "foodvisorItem": "Duchess potatoes",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Garlic",
        "foodItem": "GARLIC"
    },
    {
        "foodvisorItem": "Orange salad",
        "foodItem": "ORANGE"
    },
    {
        "foodvisorItem": "Barbecue pizza slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cashew puree",
        "foodItem": "CASHEW NUT"
    },
    {
        "foodvisorItem": "Floating island",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Potato",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Haggis",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Oil",
        "foodItem": "NUTS AND SEEDS OIL"
    },
    {
        "foodvisorItem": "Cornstarch",
        "foodItem": "MAIZE STARCH"
    },
    {
        "foodvisorItem": "Palak Paneer",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Coconut milk",
        "foodItem": "COCONUT MILK"
    },
    {
        "foodvisorItem": "Plain omelette",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Red leaf lettuce",
        "foodItem": "LETTUCE"
    },
    {
        "foodvisorItem": "Steamed cod",
        "foodItem": "COD (F)"
    },
    {
        "foodvisorItem": "Chicken leg",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Semi-skimmed goat milk",
        "foodItem": "GOAT MILK"
    },
    {
        "foodvisorItem": "Hamburger",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Cassoulet",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Chicken wrap",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Spinach",
        "foodItem": "SPINACH"
    },
    {
        "foodvisorItem": "Empanadas",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Grated carrots",
        "foodItem": "CARROT"
    },
    {
        "foodvisorItem": "Grilled pork chops",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Cooked pak choy cabbage",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Canned lentils",
        "foodItem": "PEAS CANNED"
    },
    {
        "foodvisorItem": "Nutella",
        "foodItem": "CHOCOLATE"
    },
    {
        "foodvisorItem": "Sage powder",
        "foodItem": "SAGE"
    },
    {
        "foodvisorItem": "Milkshake",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Dried chipotle chili pepper",
        "foodItem": "CHILI PEPPER"
    },
    {
        "foodvisorItem": "Pancake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Lobster Roll",
        "foodItem": "LOBSTER (W)"
    },
    {
        "foodvisorItem": "Beaufort",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Herb omelette",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Sausage and lentils",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Potato pancake",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Caesar Salad",
        "foodItem": "LETTUCE"
    },
    {
        "foodvisorItem": "Creme caramel",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Corn cob",
        "foodItem": "CORN"
    },
    {
        "foodvisorItem": "Canned peas",
        "foodItem": "PEAS CANNED"
    },
    {
        "foodvisorItem": "Salted soy sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Sauerkraut",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Pig snout",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Beef cordon bleu",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Plantain",
        "foodItem": "PLANTAIN BANANA"
    },
    {
        "foodvisorItem": "Ginger powder",
        "foodItem": "GINGER"
    },
    {
        "foodvisorItem": "Schweppes Agrum' 33 cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Montbeliard sausage",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Clusters",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Cherry jam",
        "foodItem": "RED BERRIES"
    },
    {
        "foodvisorItem": "Spring roll dipping sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Plain diced bacon",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Onion powder",
        "foodItem": "PARSLEY"
    },
    {
        "foodvisorItem": "Roasted shoulder of lamb",
        "foodItem": "LAMB"
    },
    {
        "foodvisorItem": "Dried egg pasta cooked",
        "foodItem": "EGG PASTA"
    },
    {
        "foodvisorItem": "Home-made french fries",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Dried mango",
        "foodItem": "MANGO"
    },
    {
        "foodvisorItem": "Gouda cheese",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Strawberry gateau",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cooked Chinese cabbage",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Goji berries",
        "foodItem": "RED BERRIES"
    },
    {
        "foodvisorItem": "Plain Whole Milk Greek Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "California tuna roll",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Apple sauce",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Quinoa flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Fat-free beef stock cube",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Horseradish",
        "foodItem": "HORSERADISH"
    },
    {
        "foodvisorItem": "Pastry/soft flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Burrito",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Beer can",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Strawberry tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "White spelt flour",
        "foodItem": "SPELT FLOUR"
    },
    {
        "foodvisorItem": "Conference Pear",
        "foodItem": "PEAR"
    },
    {
        "foodvisorItem": "Baklava",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Pistou soup",
        "foodItem": "PESTO"
    },
    {
        "foodvisorItem": "French toast",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Green Lentil Flour",
        "foodItem": "CHICKPEA FLOUR"
    },
    {
        "foodvisorItem": "Pizza slice with toppings",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Pad thaï",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Baking soda",
        "foodItem": "WHEAT STARCH"
    },
    {
        "foodvisorItem": "Coconut",
        "foodItem": "COCONUT"
    },
    {
        "foodvisorItem": "Leek quiche",
        "foodItem": "LEEK"
    },
    {
        "foodvisorItem": "Sangria",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "French dressing (50 to 75% oil)",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Fruit cereal bar",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Corn oil",
        "foodItem": "MAIZE OIL"
    },
    {
        "foodvisorItem": "Plain Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Hamburger McDonald's",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Dark draught beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Garlic salami",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Coconut water",
        "foodItem": "COCONUT MILK"
    },
    {
        "foodvisorItem": "Mimolette",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "S'more",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Tuna crudités mayonnaise baguette sandwich",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Dragibus",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Maki",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Compressed baker's yeast",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Mint",
        "foodItem": "MINT"
    },
    {
        "foodvisorItem": "Cucumber",
        "foodItem": "CUCUMBER"
    },
    {
        "foodvisorItem": "Schweppes 50cl",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Fourme d'Ambert",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Fresh pumpkin",
        "foodItem": "PUMPKIN"
    },
    {
        "foodvisorItem": "Custom burrito",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Sunflower oil",
        "foodItem": "SUNFLOWER OIL"
    },
    {
        "foodvisorItem": "Soy patty",
        "foodItem": "SOY BURGER"
    },
    {
        "foodvisorItem": "Lollipop",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Veal blanquette",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Vanilla Bean",
        "foodItem": "VANILLA"
    },
    {
        "foodvisorItem": "Buttermilk",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Fruity Pebbles",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Ham Croissant",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fresh salsify",
        "foodItem": "SALSIFY"
    },
    {
        "foodvisorItem": "Bottle of water",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Pork kidneys",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Kinder Bueno",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Canned celery",
        "foodItem": "CELERY"
    },
    {
        "foodvisorItem": "Home-made mashed potatoes",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Veal paupiette",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Krisprolls Golden Wheat",
        "foodItem": "CRISPBREAD"
    },
    {
        "foodvisorItem": "Saint Albray",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Corn bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fajitas",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Oil blend",
        "foodItem": "NUTS AND SEEDS OIL"
    },
    {
        "foodvisorItem": "Coca-Cola Zero",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Canned brussel sprouts",
        "foodItem": "BRUSSELS SPROUT"
    },
    {
        "foodvisorItem": "Canned broccoli",
        "foodItem": "BROCCOLI"
    },
    {
        "foodvisorItem": "Chicken curry",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Apple juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Morbier cheese",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Tahini",
        "foodItem": "SESAME SEED"
    },
    {
        "foodvisorItem": "Rhubarb tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Bun",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Coppa",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Brown sugar",
        "foodItem": "BROWN SUGAR"
    },
    {
        "foodvisorItem": "Schweppes",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Whole lemon",
        "foodItem": "LEMON"
    },
    {
        "foodvisorItem": "Spaghetti",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Mushroom risotto",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Custom club sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Shortcrust pastry",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Shrimp",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "Frosted Flakes",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Salmon tartare",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Broth",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Beef stock",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Brown mushrooms",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Carrot puree",
        "foodItem": "CARROT"
    },
    {
        "foodvisorItem": "Orange cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Chocolate puff pastry",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Beef skewer",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Tuna roll",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Double cream (30% fat)",
        "foodItem": "CREAM"
    },
    {
        "foodvisorItem": "Kebab",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Amaretti",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Ham and cheese pizza",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Porcini Mushroom",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Lobster",
        "foodItem": "LOBSTER (W)"
    },
    {
        "foodvisorItem": "Risotto",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Kale",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Palmier",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fried chicken",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Sheep Milk Plain Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Curly endive salad",
        "foodItem": "ENDIVE"
    },
    {
        "foodvisorItem": "Sweet and sour sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Cruesli original",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Baby corn",
        "foodItem": "CORN"
    },
    {
        "foodvisorItem": "Arborio rice",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Pepperoni pizza slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Pasta salad",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Babybel",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Banana juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Padrón peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Lemon sorbet",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Toffee",
        "foodItem": "BROWN SUGAR"
    },
    {
        "foodvisorItem": "Dehydrated baker's yeast",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Almond spread",
        "foodItem": "ALMOND"
    },
    {
        "foodvisorItem": "Crepe with caramel",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Flat beans",
        "foodItem": "BEAN (f)"
    },
    {
        "foodvisorItem": "Melted dark chocolate",
        "foodItem": "DARK CHOCOLATE"
    },
    {
        "foodvisorItem": "Green olives",
        "foodItem": "OLIVES"
    },
    {
        "foodvisorItem": "Wine vinegar",
        "foodItem": "VINEGAR"
    },
    {
        "foodvisorItem": "Vegetarian nuggets",
        "foodItem": "PLANT BASED PROTEIN (Generic)"
    },
    {
        "foodvisorItem": "Grain mustard",
        "foodItem": "MUSTARD"
    },
    {
        "foodvisorItem": "3D's",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Toast with butter and apricot jam",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Peas and carrots",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Dill powder",
        "foodItem": "DILL"
    },
    {
        "foodvisorItem": "Crudités and mayonnaise baguette sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Papaya",
        "foodItem": "PAPAYA"
    },
    {
        "foodvisorItem": "Rosé",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Spinach salad",
        "foodItem": "SPINACH"
    },
    {
        "foodvisorItem": "Coca-Cola 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Perch",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Half-baked chocolate cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Raisin snail",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Coffee Mocha",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Pim's",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Muesli",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Beetroot",
        "foodItem": "BEETROOT"
    },
    {
        "foodvisorItem": "Chickpeas",
        "foodItem": "CHICKPEA (d)"
    },
    {
        "foodvisorItem": "French dressing",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Paella",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Gazpacho",
        "foodItem": "TOMATO SAUCE"
    },
    {
        "foodvisorItem": "Cottage cheese",
        "foodItem": "COTTAGE CHEESE"
    },
    {
        "foodvisorItem": "Sauerbraten",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Cheese sauce",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Fruit juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Hard-boiled egg",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Bagel",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Carrot cake with icing",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Saveloy",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Soy cream",
        "foodItem": "SOY MILK"
    },
    {
        "foodvisorItem": "Sour cream",
        "foodItem": "CREAM"
    },
    {
        "foodvisorItem": "Cheeseburger",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Unsweetened coconut milk",
        "foodItem": "COCONUT MILK"
    },
    {
        "foodvisorItem": "Chicken breast",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Duck leg",
        "foodItem": "POULTRY"
    },
    {
        "foodvisorItem": "Smoked trout",
        "foodItem": "TROUT (W)"
    },
    {
        "foodvisorItem": "Ladyfingers",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Toast with red berry jam",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Couscous",
        "foodItem": "COUSCOUS"
    },
    {
        "foodvisorItem": "Cordon bleu",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Crottin de Chavignol",
        "foodItem": "GOAT CHEESE"
    },
    {
        "foodvisorItem": "Raspberry ice cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Grapefruit",
        "foodItem": "GRAPEFRUIT"
    },
    {
        "foodvisorItem": "White chocolate",
        "foodItem": "WHITE CHOCOLATE"
    },
    {
        "foodvisorItem": "Cured ham",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Butter croissant",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Chipolata sausage",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Red berry tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Nachos",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "French fries",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Wholegrain rye flour",
        "foodItem": "RYE FLOUR"
    },
    {
        "foodvisorItem": "Rice salad",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "High Protein Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Chicory",
        "foodItem": "CHICORY"
    },
    {
        "foodvisorItem": "Peach",
        "foodItem": "PEACH"
    },
    {
        "foodvisorItem": "Beef steak",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Flax seeds",
        "foodItem": "LINSEED"
    },
    {
        "foodvisorItem": "Sprite 50cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Scallop",
        "foodItem": "SCALLOP (F)"
    },
    {
        "foodvisorItem": "Agar Agar",
        "foodItem": "WHEAT STARCH"
    },
    {
        "foodvisorItem": "Ratatouille",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Royal Gala Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Coleslaw",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Prawn fritter",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "Tomato concentrate",
        "foodItem": "TOMATO PASTE"
    },
    {
        "foodvisorItem": "Semi-salted butter (60% Fat)",
        "foodItem": "BUTTER"
    },
    {
        "foodvisorItem": "Brique",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Curly",
        "foodItem": "LETTUCE"
    },
    {
        "foodvisorItem": "Macadamia nuts",
        "foodItem": "MACADAMIA"
    },
    {
        "foodvisorItem": "Mung beans",
        "foodItem": "BEAN (d)"
    },
    {
        "foodvisorItem": "Fuze Tea 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Persimmon",
        "foodItem": "PERSIMMON"
    },
    {
        "foodvisorItem": "Petit Ecolier milk chocolate",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Cooked celery",
        "foodItem": "CELERY"
    },
    {
        "foodvisorItem": "Sea bream tartare",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Pasta Gratin",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Crumbled salmon",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Shortbread",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Fruit Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Gelatin powder",
        "foodItem": "WHEAT STARCH"
    },
    {
        "foodvisorItem": "Toast with butter",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Shallot",
        "foodItem": "SHALLOT"
    },
    {
        "foodvisorItem": "Canned mackerel with tomato sauce",
        "foodItem": "MACKEREL (W)"
    },
    {
        "foodvisorItem": "Roast pork",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Frozen french fries",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Strawberry jam",
        "foodItem": "STRAWBERRY"
    },
    {
        "foodvisorItem": "Bara Brith Bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Spicy cream sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Tomato soup",
        "foodItem": "TOMATO SAUCE"
    },
    {
        "foodvisorItem": "7 Up 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Canned chichpeas",
        "foodItem": "PEAS CANNED"
    },
    {
        "foodvisorItem": "Beef nuggets",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Pizza dough with honey",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fruit tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Parsnip",
        "foodItem": "PARSNIP"
    },
    {
        "foodvisorItem": "Galette Saint Michel",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Beef roast",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Mozzarella",
        "foodItem": "MOZZARELLA"
    },
    {
        "foodvisorItem": "Frozen yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Mango pearls",
        "foodItem": "MANGO"
    },
    {
        "foodvisorItem": "Wax beans",
        "foodItem": "BEAN (f)"
    },
    {
        "foodvisorItem": "Ice Tea Light 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Dry cider",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Apple nectar",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Canned pumpkin",
        "foodItem": "PUMPKIN"
    },
    {
        "foodvisorItem": "Iceberg lettuce",
        "foodItem": "LETTUCE"
    },
    {
        "foodvisorItem": "Grape seed oil",
        "foodItem": "NUTS AND SEEDS OIL"
    },
    {
        "foodvisorItem": "Basic crepe",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Gluten-free bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Meat pie",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Fried egg (without added fat)",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Passion fruit",
        "foodItem": "PASSION FRUIT"
    },
    {
        "foodvisorItem": "Taramasalata",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Chicken Basquaise with rice",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Sweetened tea",
        "foodItem": "TEA"
    },
    {
        "foodvisorItem": "Fresh white aspargus",
        "foodItem": "ASPARAGUS"
    },
    {
        "foodvisorItem": "Chestnut flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Compote no added sugar",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Dried Figs",
        "foodItem": "FIG"
    },
    {
        "foodvisorItem": "Miso soup",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "White almond puree",
        "foodItem": "ALMOND"
    },
    {
        "foodvisorItem": "Sugar-free strawberry jam",
        "foodItem": "STRAWBERRY"
    },
    {
        "foodvisorItem": "Clotted Cream",
        "foodItem": "CREAM"
    },
    {
        "foodvisorItem": "Roast pork shoulder",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Leaf gelatin",
        "foodItem": "WHEAT STARCH"
    },
    {
        "foodvisorItem": "Nesquick cereal",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Barley flour",
        "foodItem": "BARLEY FLOUR"
    },
    {
        "foodvisorItem": "Cherries",
        "foodItem": "CHERRY"
    },
    {
        "foodvisorItem": "Clementine wedges",
        "foodItem": "CLEMENTINE"
    },
    {
        "foodvisorItem": "Sprite",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Vegetable quiche",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Ground beef (15% Fat)",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Watermelon",
        "foodItem": "MELON"
    },
    {
        "foodvisorItem": "Minced meat pie",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Camembert",
        "foodItem": "CAMEMBERT CHEESE"
    },
    {
        "foodvisorItem": "Whole spelt flour (T150)",
        "foodItem": "SPELT FLOUR"
    },
    {
        "foodvisorItem": "Vegetarian pizza slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Sweet potato",
        "foodItem": "SWEET POTATO"
    },
    {
        "foodvisorItem": "Sliced apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Pita bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Balsamic vinegar",
        "foodItem": "VINEGAR"
    },
    {
        "foodvisorItem": "Gyoza",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Soy flour",
        "foodItem": "SOY BEAN FLOUR"
    },
    {
        "foodvisorItem": "Sprite 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Fudgesicle",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Valençay",
        "foodItem": "GOAT CHEESE"
    },
    {
        "foodvisorItem": "Mini pizza Belin",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Chocolate Danette",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Unsweetened plain soymilk",
        "foodItem": "SOY MILK"
    },
    {
        "foodvisorItem": "Coconut flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Unsweetened shredded coconut",
        "foodItem": "COCONUT"
    },
    {
        "foodvisorItem": "Crepe (ham and cheese)",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Ham emmental butter baguette sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Berlingots",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "White chocolate chips",
        "foodItem": "WHITE CHOCOLATE"
    },
    {
        "foodvisorItem": "Sesame oil",
        "foodItem": "SESAME OIL"
    },
    {
        "foodvisorItem": "Boskoop Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Grilled lamb chop",
        "foodItem": "LAMB"
    },
    {
        "foodvisorItem": "Milk chocolate",
        "foodItem": "MILK CHOCOLATE"
    },
    {
        "foodvisorItem": "Flammekueche",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Crepe with bechamel and ham",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cardamom powder",
        "foodItem": "CARDAMOM"
    },
    {
        "foodvisorItem": "Diced bacon",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Unsalted butter",
        "foodItem": "BUTTER"
    },
    {
        "foodvisorItem": "Seaweed salad",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Gerlinea biscuit",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Cooked endives",
        "foodItem": "ENDIVE"
    },
    {
        "foodvisorItem": "Bresaola",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Pine nuts",
        "foodItem": "PINE NUT"
    },
    {
        "foodvisorItem": "Monaco draught beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Bottle of soda",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Hot Chocolate",
        "foodItem": "COCOA (Powder)"
    },
    {
        "foodvisorItem": "Raw onions",
        "foodItem": "ONION"
    },
    {
        "foodvisorItem": "Chestnut spread mousse",
        "foodItem": "CHESTNUT"
    },
    {
        "foodvisorItem": "Harissa",
        "foodItem": "CHILI PEPPER"
    },
    {
        "foodvisorItem": "Philadelphia",
        "foodItem": "CREAM CHEESE"
    },
    {
        "foodvisorItem": "Milk",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Liqueur shot",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Toast with butter and strawberry jam",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Buckwheat flakes",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Poutine",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Pasta with grated cheese",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Coffee Latte",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Croutons",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Guinea fowl leg",
        "foodItem": "GUINEA FOWL"
    },
    {
        "foodvisorItem": "Chocolate waffle",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Crab surimi",
        "foodItem": "CRAB (W)"
    },
    {
        "foodvisorItem": "Light cream (15%)",
        "foodItem": "CREAM"
    },
    {
        "foodvisorItem": "Rosette and butter baguette sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "High Protein Plain Yogurt (0%)",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Shrimp sushi",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "Soy Plain Yogurt",
        "foodItem": "SOY MILK"
    },
    {
        "foodvisorItem": "Marmite spread",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Wholewheat sandwich bread",
        "foodItem": "BREAD WHOLE"
    },
    {
        "foodvisorItem": "Sugar doughnut",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Creamy chicken",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Blackcurrant",
        "foodItem": "CURRANT"
    },
    {
        "foodvisorItem": "Carrot cake without icing",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Roquefort",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Skyr",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Four-cheese pizza slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Walnuts",
        "foodItem": "WALNUT"
    },
    {
        "foodvisorItem": "Worcestershire sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Bacon omelette",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Moussaka",
        "foodItem": "EGGPLANT (AUBERGIN)"
    },
    {
        "foodvisorItem": "Potatoes with skin",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Chicken McNuggets",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Activia Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Honey Smacks",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Spring roll",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Custom sandwich baguette",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Kitkat",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Halibut",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Russian cigarette",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Chantecler Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Pineapple juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Condensed milk",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Danone plain Yogurt",
        "foodItem": "YOG"
    },
    {
        "foodvisorItem": "Cointreau",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Ravioli with tomato sauce",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Americano coffee",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Cereal patty",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Sweet potato fries",
        "foodItem": "SWEET POTATO"
    },
    {
        "foodvisorItem": "Tea biscuits",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Plain muffin",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Nut mix",
        "foodItem": "NUTS (Generic)"
    },
    {
        "foodvisorItem": "Butter sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Prune custard flan",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Salmon Spread",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Semi wholemeal rye flour",
        "foodItem": "RYE FLOUR"
    },
    {
        "foodvisorItem": "Canned tuna with tomato",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Traditional pork rillettes",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Activia Vanilla Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Fried rice",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Coca-Cola Life",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Baked beans",
        "foodItem": "BEAN (d)"
    },
    {
        "foodvisorItem": "Chickpea flour",
        "foodItem": "CHICKPEA FLOUR"
    },
    {
        "foodvisorItem": "Sweet vanilla almond milk",
        "foodItem": "ALMOND MILK"
    },
    {
        "foodvisorItem": "Navette biscuit",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Orange wedges",
        "foodItem": "ORANGE"
    },
    {
        "foodvisorItem": "Chicken filet",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Mars Ice Cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Pasta all'arrabbiata",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Chocolate Liegeois",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Chocolate barquette",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Wholegrain spelt flour",
        "foodItem": "SPELT FLOUR"
    },
    {
        "foodvisorItem": "Skimmed milk powder",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Whey",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Canned peeled tomatoes",
        "foodItem": "TOMATO PEELED"
    },
    {
        "foodvisorItem": "Exotic chinese soup",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Veal kidneys",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Raw green bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Breaded cutlet",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Smoked salmon and butter baguette sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Tuna rillettes",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Vacherin Mont-d'Or",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Caramelized pork",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Quinoa",
        "foodItem": "QUINOA"
    },
    {
        "foodvisorItem": "Port-Salut",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Cooked yellow bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Plain cereal bar",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Cannelloni",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Gorgonzola",
        "foodItem": "GORGONZOLA"
    },
    {
        "foodvisorItem": "Grated Parmesan cheese",
        "foodItem": "PARMIGIANO REGGIANO"
    },
    {
        "foodvisorItem": "White wheat flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Granny smith Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Dark chocolate chips",
        "foodItem": "DARK CHOCOLATE"
    },
    {
        "foodvisorItem": "Sea bream sushi",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Eggplant caviar",
        "foodItem": "EGGPLANT (AUBERGIN)"
    },
    {
        "foodvisorItem": "Artichoke bottom",
        "foodItem": "ARTICHOKE"
    },
    {
        "foodvisorItem": "Blueberry jam",
        "foodItem": "BLUEBERRY"
    },
    {
        "foodvisorItem": "Apricot tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cuba libre",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Poilâne bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Raclette",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Vegetable gratin",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Fish quenelle",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Sardine in oil",
        "foodItem": "\"\"GLOBAL SARDINE (W)\"\""
    },
    {
        "foodvisorItem": "Peach Melba",
        "foodItem": "PEACH"
    },
    {
        "foodvisorItem": "Pike",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Vanilla milkshake",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Salty sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Cuttlefish",
        "foodItem": "CUTTELFISH (W)"
    },
    {
        "foodvisorItem": "Petit suisse 0%",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Whipped cream",
        "foodItem": "CREAM"
    },
    {
        "foodvisorItem": "Mashed potatoes",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Soft-boiled egg",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Poultry skewer",
        "foodItem": "Poultry&Rabbit (General)"
    },
    {
        "foodvisorItem": "Cheese pizza",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cooked rice",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "French dressing with olive oil",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Broccoli",
        "foodItem": "BROCCOLI"
    },
    {
        "foodvisorItem": "Roast beef",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Veal cutlet",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Organic chocolate muesli",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Mushrooms",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Grape juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Flax seed flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Light coconut milk",
        "foodItem": "COCONUT MILK"
    },
    {
        "foodvisorItem": "Black beans",
        "foodItem": "BEAN (d)"
    },
    {
        "foodvisorItem": "Canadian bacon",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Ramen",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Foie gras",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Gummy candy",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Prosciutto mozzarella and tomatoes panini",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cornish Pasty",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Pasteis de Nata",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Baking powder",
        "foodItem": "WHEAT STARCH"
    },
    {
        "foodvisorItem": "Mozzarella stick",
        "foodItem": "MOZZARELLA"
    },
    {
        "foodvisorItem": "Ice cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Penne",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Non-fat Fruit Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Wholemeal rye flour",
        "foodItem": "RYE FLOUR"
    },
    {
        "foodvisorItem": "Petits fours",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Grilled salmon",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Reinette Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Special K Original",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Mortadella",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "California salmon roll",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Yam",
        "foodItem": "YAM"
    },
    {
        "foodvisorItem": "Pizza crust",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "All-purpose/plain flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Brie",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Sunflower seed",
        "foodItem": "SUNFLOWER SEED"
    },
    {
        "foodvisorItem": "Cheese soufflé",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Custom tacos",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Canned carrots",
        "foodItem": "CARROT"
    },
    {
        "foodvisorItem": "Cookie",
        "foodItem": "SIMPLE COOKIES"
    },
    {
        "foodvisorItem": "Fish rillettes",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Red mullet",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Ariane Apple",
        "foodItem": "APPLE"
    },
    {
        "foodvisorItem": "Black olives",
        "foodItem": "OLIVES"
    },
    {
        "foodvisorItem": "Egg yolk",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Smoked salmon",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Toast with fish rillettes",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Raw hulled barley",
        "foodItem": "BARLEY"
    },
    {
        "foodvisorItem": "Celery",
        "foodItem": "CELERY"
    },
    {
        "foodvisorItem": "Tripe",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Salmon and sorrel quiche",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Red Velvet Cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Lentil salad",
        "foodItem": "LENTIL (d)"
    },
    {
        "foodvisorItem": "White grape",
        "foodItem": "GRAPES"
    },
    {
        "foodvisorItem": "Cupcake without icing",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Chicory coffee",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Blini",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Tagliatelle",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Christmas Cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Brioche",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Blueberries",
        "foodItem": "BLUEBERRY"
    },
    {
        "foodvisorItem": "Black trumpet mushroom",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Raw Tuna",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Water syrup",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Shrimp dumpling",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "7 Up",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Lime",
        "foodItem": "LIME"
    },
    {
        "foodvisorItem": "Custard cream",
        "foodItem": "CREAM"
    },
    {
        "foodvisorItem": "Pho",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Chole Chana Masala",
        "foodItem": "CHICKPEA (d)"
    },
    {
        "foodvisorItem": "Mirabelle plum",
        "foodItem": "PLUM"
    },
    {
        "foodvisorItem": "Deluxe Potatoes",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Cooked broad beans",
        "foodItem": "FABA BEAN (f)"
    },
    {
        "foodvisorItem": "Poached egg",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Boursin",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Fresh lemongrass",
        "foodItem": "PARSLEY"
    },
    {
        "foodvisorItem": "Almond croissant",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Raw salmon",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Pasta Bolognese",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Dauphine potatoes",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Sea bream",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Bouquet garni",
        "foodItem": "PARSLEY"
    },
    {
        "foodvisorItem": "Truffle",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Lion Cereal",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Sriracha",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Chouquette",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Martini",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Chocolate cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Dried tarragon",
        "foodItem": "TARRAGON"
    },
    {
        "foodvisorItem": "Mushroom soup",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Saint-Paulin",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Tarte Tatin",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Celery Stalk",
        "foodItem": "CELERY"
    },
    {
        "foodvisorItem": "Toasted bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Unsweetened vanilla almondmilk",
        "foodItem": "ALMOND MILK"
    },
    {
        "foodvisorItem": "Xylitol",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Sweet Plain Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Expresso",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Chocolate milk",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Fruit in syrup",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Smoked diced bacon",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Sorghum flour",
        "foodItem": "SORGHUM FLOUR"
    },
    {
        "foodvisorItem": "Colombo spice mix",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Desperados",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Pom'Potes",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Ham and butter baguette sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Espelette pepper powder",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "CBO",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Blue ling",
        "foodItem": "LING (W)"
    },
    {
        "foodvisorItem": "Canned crushed tomatoes",
        "foodItem": "TOMATO CHOPPED"
    },
    {
        "foodvisorItem": "Leberkäse",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Sheep milk",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Doughnut",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Green aspargus",
        "foodItem": "ASPARAGUS"
    },
    {
        "foodvisorItem": "Tomato and meat sauce",
        "foodItem": "TOMATO SAUCE"
    },
    {
        "foodvisorItem": "Prunes",
        "foodItem": "RAISIN"
    },
    {
        "foodvisorItem": "Banana split",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Toast with apricot jam",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Blueberry Pancake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Flammekueche slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Plain buckwheat crepe",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fresh lemon juice",
        "foodItem": "LEMON"
    },
    {
        "foodvisorItem": "Vegetable soup",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Chicken tikka masala",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Swiss roll",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Raw orange bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Soybeans",
        "foodItem": "SOYBEAN (d)"
    },
    {
        "foodvisorItem": "Kebab meat",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Spritz",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Cassava",
        "foodItem": "MANIOCA"
    },
    {
        "foodvisorItem": "Frozen zucchini",
        "foodItem": "ZUCCHINI"
    },
    {
        "foodvisorItem": "Whiting",
        "foodItem": "WHITING (W)"
    },
    {
        "foodvisorItem": "Cherry tomatoes",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Whole almond flour",
        "foodItem": "ALMOND"
    },
    {
        "foodvisorItem": "Medjool date",
        "foodItem": "DATE"
    },
    {
        "foodvisorItem": "White mushrooms",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Kir royal",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Beef with onions",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Plain waffle",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Hot-dog bun",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Peanut butter",
        "foodItem": "PEANUT"
    },
    {
        "foodvisorItem": "Stew",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Energy drink",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Oasis",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Salmon panini",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Beef and carrots",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Redcurrant",
        "foodItem": "CURRANT"
    },
    {
        "foodvisorItem": "Meringue",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Cutlet",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Yop",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Black Coffee",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Coca-Cola 50cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Bowl cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Palm hearts",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Vanilla Danette",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Protein bar",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Corn tortillas",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Semi-salted butter",
        "foodItem": "BUTTER"
    },
    {
        "foodvisorItem": "Vodka shot",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Pepsi Next",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Custom English breakfast",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Roasted vegetables",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "White bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cobb salad",
        "foodItem": "LEAFY VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Mushroom sauce",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Sheperds pie",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Anchovies",
        "foodItem": "\"\"GLOBAL ANCHOVY (W)\"\""
    },
    {
        "foodvisorItem": "Whole Cayenne pepper",
        "foodItem": "CHILI PEPPER"
    },
    {
        "foodvisorItem": "Surffizz",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Bamboo shoots",
        "foodItem": "BAMBOO"
    },
    {
        "foodvisorItem": "Stuffed tomatoes",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Fish nuggets",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Melon",
        "foodItem": "MELON"
    },
    {
        "foodvisorItem": "Lou Pérac",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Jam doughnut",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Toulouse sausage",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Twelfth-Night cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cinnamon powder",
        "foodItem": "CINNAMON"
    },
    {
        "foodvisorItem": "Paraffin oil",
        "foodItem": "NUTS AND SEEDS OIL"
    },
    {
        "foodvisorItem": "Cheese fondue",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Oat milk",
        "foodItem": "OAT MILK"
    },
    {
        "foodvisorItem": "Celery salad",
        "foodItem": "CELERY"
    },
    {
        "foodvisorItem": "Margarine",
        "foodItem": "MARGARINE"
    },
    {
        "foodvisorItem": "Skate",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Canned corn",
        "foodItem": "CORN"
    },
    {
        "foodvisorItem": "Hawaiian pizza slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Honey Pops",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Dark chocolate",
        "foodItem": "DARK CHOCOLATE"
    },
    {
        "foodvisorItem": "Raw yellow bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Cereal bar",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Acid candies",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Melted butter",
        "foodItem": "BUTTER"
    },
    {
        "foodvisorItem": "Prawn",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "Pepsi Next 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Chinese noodles",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Tomato mozzarella salad",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Salmon",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Konjac",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Banana milkshake",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Turkey crudités mayonnaise baguette sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Polenta",
        "foodItem": "MAIZE FLOUR"
    },
    {
        "foodvisorItem": "Butter",
        "foodItem": "BUTTER"
    },
    {
        "foodvisorItem": "Apple strawberry compote",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Sun-dried tomatoes in oil",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Lemon zest",
        "foodItem": "LEMON"
    },
    {
        "foodvisorItem": "Pasta with salmon",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Krema",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Lamb tajine",
        "foodItem": "LAMB"
    },
    {
        "foodvisorItem": "Detox Tea",
        "foodItem": "TEA"
    },
    {
        "foodvisorItem": "Canned eggplant",
        "foodItem": "EGGPLANT (AUBERGIN)"
    },
    {
        "foodvisorItem": "Merguez and fries baguette sandwich",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Beef stock cube",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Tomato juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Tuna quiche",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Toast with cheese",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Delicata squash",
        "foodItem": "SQUASH"
    },
    {
        "foodvisorItem": "Ravioli",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Raw red bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Canned crab",
        "foodItem": "CRAB (W)"
    },
    {
        "foodvisorItem": "Goat Milk Plain Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Orangina",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Peeled potato",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Feijoada",
        "foodItem": "BEAN (d)"
    },
    {
        "foodvisorItem": "Fat-free chicken stock cube",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Coconut sugar",
        "foodItem": "BROWN SUGAR"
    },
    {
        "foodvisorItem": "Raw bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Fraises Tagada",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Masala sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Cod brandade",
        "foodItem": "COD (F)"
    },
    {
        "foodvisorItem": "Ricotta and spinach lasagna",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Wasabi",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Deviled egg",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Pizza Regina slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Vanilla cream",
        "foodItem": "CREAM"
    },
    {
        "foodvisorItem": "Prawn curry",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "Pumpkin puree",
        "foodItem": "PUMPKIN"
    },
    {
        "foodvisorItem": "Gerblé biscuit",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Lychee",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Pork and cabbage hotpot",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Jaffa cake",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Orange",
        "foodItem": "ORANGE"
    },
    {
        "foodvisorItem": "Fruit cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Dried apricots",
        "foodItem": "FIG"
    },
    {
        "foodvisorItem": "Chicken noodle soup",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Pita bread whole wheat",
        "foodItem": "BREAD WHOLE"
    },
    {
        "foodvisorItem": "Vermicelli",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Monkfish",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Vegetable stock",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Frozen peas",
        "foodItem": "GREEN BEAN"
    },
    {
        "foodvisorItem": "Almond extract",
        "foodItem": "ALMOND"
    },
    {
        "foodvisorItem": "Proti'dej",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Lychee (in shell)",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Pecan nuts",
        "foodItem": "PECAN NUT"
    },
    {
        "foodvisorItem": "Mango",
        "foodItem": "MANGO"
    },
    {
        "foodvisorItem": "Turnip",
        "foodItem": "TURNIP"
    },
    {
        "foodvisorItem": "Unleavened bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Coca-Cola",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Saithe",
        "foodItem": "SAITHE (W)"
    },
    {
        "foodvisorItem": "Ground beef",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Margarine light",
        "foodItem": "MARGARINE"
    },
    {
        "foodvisorItem": "Watercress",
        "foodItem": "LETTUCE"
    },
    {
        "foodvisorItem": "Dried bananas",
        "foodItem": "BANANA"
    },
    {
        "foodvisorItem": "English muffin",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Rocamadour",
        "foodItem": "GOAT CHEESE"
    },
    {
        "foodvisorItem": "Ground cumin",
        "foodItem": "CUMIN"
    },
    {
        "foodvisorItem": "Amaranth",
        "foodItem": "GRAINS (Generic)"
    },
    {
        "foodvisorItem": "Orange jam",
        "foodItem": "ORANGE"
    },
    {
        "foodvisorItem": "Vanilla Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Chicken wings",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Vanilla ice cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Chirashi",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Wholegrain kamut flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Vegetable tian",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Schweppes Lemon 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Fougasse",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Mirabelle plum jam",
        "foodItem": "PLUM"
    },
    {
        "foodvisorItem": "Pastis",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Quesadillas",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Tofu",
        "foodItem": "TOFU"
    },
    {
        "foodvisorItem": "Melon juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Einkorn whole grain flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Coconut extract",
        "foodItem": "COCONUT"
    },
    {
        "foodvisorItem": "Greek-style mushrooms",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Red berry coulis",
        "foodItem": "RED BERRIES"
    },
    {
        "foodvisorItem": "Eel",
        "foodItem": "EEL (W)"
    },
    {
        "foodvisorItem": "Parmesan",
        "foodItem": "PARMIGIANO REGGIANO"
    },
    {
        "foodvisorItem": "Apple turnover",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "M&Ms",
        "foodItem": "MILK CHOCOLATE"
    },
    {
        "foodvisorItem": "Country terrine",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Raspberry Jaffa cake",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Mixed fruit nectar",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Spaghetti squash",
        "foodItem": "SQUASH"
    },
    {
        "foodvisorItem": "Rum shot",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Enchiladas",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Bottle of sparkling water",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Black Radish",
        "foodItem": "RADISH"
    },
    {
        "foodvisorItem": "Sauteed vegetables",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Big Mac",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Laurel leaf",
        "foodItem": "\"\"BAY, DRIED\"\""
    },
    {
        "foodvisorItem": "Quenelle",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Tomato",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Carrot soup",
        "foodItem": "CARROT"
    },
    {
        "foodvisorItem": "Raw mackerel",
        "foodItem": "MACKEREL (W)"
    },
    {
        "foodvisorItem": "Coconut flakes",
        "foodItem": "COCONUT"
    },
    {
        "foodvisorItem": "Zucchini gratin",
        "foodItem": "ZUCCHINI"
    },
    {
        "foodvisorItem": "Bottled dark beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Carambar",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Plain wheat sandwich bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cheddar",
        "foodItem": "CHEDDAR CHEESE"
    },
    {
        "foodvisorItem": "Huevos rancheros",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Lemonade",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Blue cheese",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Lamb Skewer",
        "foodItem": "LAMB"
    },
    {
        "foodvisorItem": "Provençal tomatoes",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Couscous Royale",
        "foodItem": "COUSCOUS"
    },
    {
        "foodvisorItem": "Ras el hanout",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Spider crab",
        "foodItem": "CRAB (W)"
    },
    {
        "foodvisorItem": "Vienna bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Brown rice",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Wholewheat pasta",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Carrot juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Cheese Spätzle",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Semolina pudding",
        "foodItem": "DURUM WHEAT SEMOLINA"
    },
    {
        "foodvisorItem": "Emmental",
        "foodItem": "EMMENTAL"
    },
    {
        "foodvisorItem": "Schweppes Zero 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Semi hard-boiled egg",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Blueberry pie",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Vegetable pudding",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "White draught beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Seitan",
        "foodItem": "PLANT BASED PROTEIN (Generic)"
    },
    {
        "foodvisorItem": "Damas Plum",
        "foodItem": "PLUM"
    },
    {
        "foodvisorItem": "Pastrami",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Nuggets",
        "foodItem": "Poultry&Rabbit (General)"
    },
    {
        "foodvisorItem": "Black fungus",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Protein powder",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Canned brussel sprouts",
        "foodItem": "BRUSSELS SPROUT"
    },
    {
        "foodvisorItem": "Cooked white cabbage",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Opéra",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Rye bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Whole milk powder",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Apple cider vinegar",
        "foodItem": "VINEGAR"
    },
    {
        "foodvisorItem": "Cut avocado",
        "foodItem": "AVOCADO"
    },
    {
        "foodvisorItem": "Ham bacon",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "French gruyère",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Pureed celery root",
        "foodItem": "CELERIAC"
    },
    {
        "foodvisorItem": "Cranberries",
        "foodItem": "CRANBERRY"
    },
    {
        "foodvisorItem": "Goat cheese log",
        "foodItem": "GOAT CHEESE"
    },
    {
        "foodvisorItem": "Cooked green bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Mediterranean vegetable stock",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Horse steak",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Canned peas and carrots",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Raspberry cake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Hazelnut powder",
        "foodItem": "HAZELNUT"
    },
    {
        "foodvisorItem": "Prime rib",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Sea urchins",
        "foodItem": "BIVALVES (generic)"
    },
    {
        "foodvisorItem": "Rice cake",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Mentos",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Whelk",
        "foodItem": "BIVALVES (generic)"
    },
    {
        "foodvisorItem": "Poultry stock",
        "foodItem": "Poultry&Rabbit (General)"
    },
    {
        "foodvisorItem": "Scrambled eggs",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Chard",
        "foodItem": "SWISS CHARD"
    },
    {
        "foodvisorItem": "Orange nectar",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Leerdammer",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Acai bowl",
        "foodItem": "Berries (Generic)"
    },
    {
        "foodvisorItem": "Raspberry jam",
        "foodItem": "RASPBERRY"
    },
    {
        "foodvisorItem": "Cooked ham",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Almond paste",
        "foodItem": "ALMOND"
    },
    {
        "foodvisorItem": "Tapioca starch",
        "foodItem": "MANIOCA STARCH"
    },
    {
        "foodvisorItem": "Beef tenderloin",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Oats",
        "foodItem": "OAT"
    },
    {
        "foodvisorItem": "Chicken meatballs",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Carp",
        "foodItem": "CARP (F)"
    },
    {
        "foodvisorItem": "Zucchini flan",
        "foodItem": "ZUCCHINI"
    },
    {
        "foodvisorItem": "Skittles",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Fresh dill",
        "foodItem": "DILL"
    },
    {
        "foodvisorItem": "Salmon maki",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Chicken liver pâté",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Kamut flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Chocolate cereals",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Matcha",
        "foodItem": "TEA"
    },
    {
        "foodvisorItem": "Beef hanger steak",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Hawaiian pizza",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fresh egg pasta",
        "foodItem": "EGG PASTA"
    },
    {
        "foodvisorItem": "Strawberry milkshake",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Chocolate muffin",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Compote",
        "foodItem": "OTHER FRUITS (Generic)"
    },
    {
        "foodvisorItem": "Unsweetened white grape juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Meat stuffing",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Oatmeal Squares",
        "foodItem": "OATMEAL FLAKES"
    },
    {
        "foodvisorItem": "Strawberry BN",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Chicken cordon bleu",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Mustard",
        "foodItem": "MUSTARD"
    },
    {
        "foodvisorItem": "Diet Coke 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Candy Mixes",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Salmon wrap",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Frozen green beans",
        "foodItem": "GREEN BEAN"
    },
    {
        "foodvisorItem": "Canary melon",
        "foodItem": "MELON"
    },
    {
        "foodvisorItem": "French onion soup",
        "foodItem": "ONION"
    },
    {
        "foodvisorItem": "Cheese naan",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Glass of water",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Nutritional yeast",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Beefsteak tomatoes",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Savoyarde pizza slice",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Pepito",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Religieuse",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Crepe (egg ham and cheese)",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Pumpkin soup",
        "foodItem": "PUMPKIN"
    },
    {
        "foodvisorItem": "Saffron",
        "foodItem": "SAFFRON"
    },
    {
        "foodvisorItem": "Veal liver",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Onion rings",
        "foodItem": "ONION"
    },
    {
        "foodvisorItem": "Ketchup",
        "foodItem": "KETCHUP"
    },
    {
        "foodvisorItem": "Suprem Force 7",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Curry sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Cinnamon roll",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Hush puppies",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Strawberries",
        "foodItem": "STRAWBERRY"
    },
    {
        "foodvisorItem": "Salt-free vegetable stock cube",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Nougat",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Chocolate mousse",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Strasbourg sausage",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Pasta Carbonara",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Chili pepper",
        "foodItem": "CHILI PEPPER"
    },
    {
        "foodvisorItem": "Whole artichoke",
        "foodItem": "ARTICHOKE"
    },
    {
        "foodvisorItem": "Fresh lentils",
        "foodItem": "BEAN (f)"
    },
    {
        "foodvisorItem": "Almonds",
        "foodItem": "ALMOND"
    },
    {
        "foodvisorItem": "Pepper",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Silken tofu",
        "foodItem": "TOFU"
    },
    {
        "foodvisorItem": "Happy Cola",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Chocolate Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Lard",
        "foodItem": "BUTTER"
    },
    {
        "foodvisorItem": "Amaranth flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Raw andouille",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Sweet soy sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Toast with rillettes",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "William Pear",
        "foodItem": "PEAR"
    },
    {
        "foodvisorItem": "Peanuts",
        "foodItem": "PEANUT"
    },
    {
        "foodvisorItem": "Anchovy in oil",
        "foodItem": "\"\"GLOBAL ANCHOVY (W)\"\""
    },
    {
        "foodvisorItem": "Actimel",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Oatmeal pancake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Passata",
        "foodItem": "TOMATO SAUCE"
    },
    {
        "foodvisorItem": "Rice vinegar",
        "foodItem": "VINEGAR"
    },
    {
        "foodvisorItem": "Feta cheese",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Chocolate ice cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Duck confit",
        "foodItem": "POULTRY"
    },
    {
        "foodvisorItem": "Granola",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Peanut butter 100%",
        "foodItem": "PEANUT"
    },
    {
        "foodvisorItem": "Veal brisket",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Weetabix cereals",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Mars",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Oasis Zero 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Smoked sausage",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Cooked fennel",
        "foodItem": "FENNEL"
    },
    {
        "foodvisorItem": "Oatmeal pancake",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Croque-madame",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "High Protein Plain Yogurt (3%)",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Steamed sole",
        "foodItem": "SOLE (W)"
    },
    {
        "foodvisorItem": "Etorki",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Ossobuco",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Chicken Basquaise with pasta",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Snickers",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Crackers",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Edam",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Coq au vin",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Chicken vol-au-vent",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Fresh thyme",
        "foodItem": "THYME"
    },
    {
        "foodvisorItem": "Steamed salmon",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Seafood risotto",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Goose",
        "foodItem": "GOOSE"
    },
    {
        "foodvisorItem": "Caviar",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Whole Milk Plain Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Lobster bisque",
        "foodItem": "LOBSTER (W)"
    },
    {
        "foodvisorItem": "Ground beef (20% Fat)",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Dried Basil",
        "foodItem": "BASIL"
    },
    {
        "foodvisorItem": "Ebly Pure Durum Wheat",
        "foodItem": "WHEAT"
    },
    {
        "foodvisorItem": "Potato wedges",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Veal shank",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Smoothie",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Grilled sardine",
        "foodItem": "\"\"GLOBAL SARDINE (W)\"\""
    },
    {
        "foodvisorItem": "Canned tuna in oil",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Lupin flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Raw buckwheat pasta",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Whole milk",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Fanta",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Hare stew",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Fresh white beans",
        "foodItem": "BEAN (f)"
    },
    {
        "foodvisorItem": "Fennel seeds",
        "foodItem": "FENNEL SEED"
    },
    {
        "foodvisorItem": "Endive and ham gratin",
        "foodItem": "ENDIVE"
    },
    {
        "foodvisorItem": "Dried sage leaf",
        "foodItem": "SAGE"
    },
    {
        "foodvisorItem": "New potatoes",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Ice Tea Light 50cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Unsalted butter (40% Fat)",
        "foodItem": "BUTTER"
    },
    {
        "foodvisorItem": "Rouladen",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Canned mackerel in white wine",
        "foodItem": "MACKEREL (W)"
    },
    {
        "foodvisorItem": "Fresh ginger",
        "foodItem": "GINGER"
    },
    {
        "foodvisorItem": "Canned tuna",
        "foodItem": "\"\"TUNA, OTHER (W)\"\""
    },
    {
        "foodvisorItem": "Pistachios",
        "foodItem": "PISTACHIO"
    },
    {
        "foodvisorItem": "Fresh peas",
        "foodItem": "GREEN BEAN"
    },
    {
        "foodvisorItem": "Pizza Calzone",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Fruit scone",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Grated cheese",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Sardine in tomato sauce",
        "foodItem": "\"\"GLOBAL SARDINE (W)\"\""
    },
    {
        "foodvisorItem": "Beef tongue",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Tabasco sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Morel",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Knuckle of ham",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Coca Cola Life 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Fried fish",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Coffee Liegeois",
        "foodItem": "COFFEE GROUND & PARCHMENT"
    },
    {
        "foodvisorItem": "Fresh rosemary",
        "foodItem": "ROSEMARY"
    },
    {
        "foodvisorItem": "Tomato salad",
        "foodItem": "TOMATO"
    },
    {
        "foodvisorItem": "Grilled bell peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Red berry crumble",
        "foodItem": "RED BERRIES"
    },
    {
        "foodvisorItem": "Glass of flavored water",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Mullet",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Candyfloss",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Spanish omelette",
        "foodItem": "EGGS"
    },
    {
        "foodvisorItem": "Pasta with tomato sauce",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Steamed potatoes",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Livarot",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Agave syrup",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Lyon rosette",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Gluten-free rice pasta",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Hare",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Non-Fat Plain Yogurt",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Beef Wellington",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Monster Munch",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Cream puff",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Tiramisu",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Bread (traditionnal French baguette)",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Mango juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Chicken stock cube",
        "foodItem": "CHICKEN"
    },
    {
        "foodvisorItem": "Oyster",
        "foodItem": "OYSTER (F)"
    },
    {
        "foodvisorItem": "Salmon roe",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "Mixed fruit juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Crawfish",
        "foodItem": "FRESHWATER CRAYFISH (F)"
    },
    {
        "foodvisorItem": "Red cabbage",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Cabbage salad",
        "foodItem": "CABBAGE"
    },
    {
        "foodvisorItem": "Apple tart",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Glass of sparkling water",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Ice Tea 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Haddock",
        "foodItem": "HADDOCK (W)"
    },
    {
        "foodvisorItem": "Zucchini",
        "foodItem": "ZUCCHINI"
    },
    {
        "foodvisorItem": "White sugar",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Pork chop (backbone)",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Lamb's lettuce",
        "foodItem": "LETTUCE"
    },
    {
        "foodvisorItem": "Tater tot",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Bounty",
        "foodItem": "CHOCOLATE OR CREAM FILLED COOKIES"
    },
    {
        "foodvisorItem": "Vegetarian pizza",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Whole lime",
        "foodItem": "LIME"
    },
    {
        "foodvisorItem": "Shawarma",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Gingerbread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Can of soda",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Stuffed Fresh pasta",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Arlequin",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Bottle of still water",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Custom fajitas",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Plum",
        "foodItem": "PLUM"
    },
    {
        "foodvisorItem": "Maultaschen",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Tomato sauce",
        "foodItem": "TOMATO SAUCE"
    },
    {
        "foodvisorItem": "Stuffed zucchini",
        "foodItem": "ZUCCHINI"
    },
    {
        "foodvisorItem": "Wild boar stew",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Mix of cooked peppers",
        "foodItem": "PEPPER"
    },
    {
        "foodvisorItem": "Gummy bear",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "Boletus mushroom",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Capers",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Canned salsify",
        "foodItem": "SALSIFY"
    },
    {
        "foodvisorItem": "Pearl onion",
        "foodItem": "ONION"
    },
    {
        "foodvisorItem": "Sauteed beef",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Cantal",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Cheese ravioli",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Parsley",
        "foodItem": "PARSLEY"
    },
    {
        "foodvisorItem": "Hummus",
        "foodItem": "CHICKPEA (d)"
    },
    {
        "foodvisorItem": "Almond milk",
        "foodItem": "ALMOND MILK"
    },
    {
        "foodvisorItem": "Hot and sour soup",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Oasis 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Veal stew",
        "foodItem": "VEAL"
    },
    {
        "foodvisorItem": "Sea Bass",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Quail",
        "foodItem": "Poultry&Rabbit (General)"
    },
    {
        "foodvisorItem": "Poire Belle Hélène",
        "foodItem": "PEAR"
    },
    {
        "foodvisorItem": "Madeleine",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Millet flour",
        "foodItem": "MILLET FLOUR"
    },
    {
        "foodvisorItem": "White rice flour",
        "foodItem": "RICE FLOUR"
    },
    {
        "foodvisorItem": "Cooked celeriac",
        "foodItem": "CELERIAC"
    },
    {
        "foodvisorItem": "Raisins",
        "foodItem": "RAISIN"
    },
    {
        "foodvisorItem": "Low fat sour cream",
        "foodItem": "CREAM"
    },
    {
        "foodvisorItem": "Tuc",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Puff pastry",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Herbes de Provence",
        "foodItem": "PARSLEY"
    },
    {
        "foodvisorItem": "Walnut oil",
        "foodItem": "NUTS AND SEEDS OIL"
    },
    {
        "foodvisorItem": "Periwinkle",
        "foodItem": "BIVALVES (generic)"
    },
    {
        "foodvisorItem": "Coca-Cola Zero 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Corn flakes",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Rum baba",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Oyster mushroom",
        "foodItem": "MUSHROOM"
    },
    {
        "foodvisorItem": "Oyster sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Julienned vegetables",
        "foodItem": "OTHER VEGETABLES (Generic)"
    },
    {
        "foodvisorItem": "Savoyarde pizza",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Gratin dauphinois",
        "foodItem": "POTATO"
    },
    {
        "foodvisorItem": "Crab",
        "foodItem": "CRAB (W)"
    },
    {
        "foodvisorItem": "Canned mackerel with mustard",
        "foodItem": "MACKEREL (W)"
    },
    {
        "foodvisorItem": "Goat cheese",
        "foodItem": "GOAT CHEESE"
    },
    {
        "foodvisorItem": "Meat cannelloni",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Fish terrine",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Paupiette",
        "foodItem": "Red meat (General)"
    },
    {
        "foodvisorItem": "Pont-l'Évêque",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Turkey cordon bleu",
        "foodItem": "TURKEY"
    },
    {
        "foodvisorItem": "Butternut squash",
        "foodItem": "SQUASH"
    },
    {
        "foodvisorItem": "Fish sticks",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Artichoke heart",
        "foodItem": "ARTICHOKE"
    },
    {
        "foodvisorItem": "Saint-Marcellin Cheese",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Fresh tarragon",
        "foodItem": "TARRAGON"
    },
    {
        "foodvisorItem": "Pasta with vegetables",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Bottled strong beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Scampi",
        "foodItem": "SHRIMP (W)"
    },
    {
        "foodvisorItem": "Cream sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Fresh sage",
        "foodItem": "SAGE"
    },
    {
        "foodvisorItem": "Plain scone",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Milk hazelnut chocolate",
        "foodItem": "MILK CHOCOLATE"
    },
    {
        "foodvisorItem": "Salt pork with lentils",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Blond draught beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Curcuma",
        "foodItem": "TURMERIC"
    },
    {
        "foodvisorItem": "Green beans",
        "foodItem": "GREEN BEAN"
    },
    {
        "foodvisorItem": "Truffle risotto",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Rice with tomato sauce",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Churros",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Lentil soup",
        "foodItem": "LENTIL (d)"
    },
    {
        "foodvisorItem": "Peanut butter toast",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Babyback ribs",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Pineapple",
        "foodItem": "PINEAPPLE"
    },
    {
        "foodvisorItem": "Coulommiers",
        "foodItem": "FRESH CHEESE (Generic)"
    },
    {
        "foodvisorItem": "Raw linguine",
        "foodItem": "PASTA"
    },
    {
        "foodvisorItem": "Red chicory",
        "foodItem": "CHICORY"
    },
    {
        "foodvisorItem": "Cereal bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Bottled blond beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Banan's Haribo",
        "foodItem": "WHITE SUGAR"
    },
    {
        "foodvisorItem": "White beans",
        "foodItem": "BEAN (d)"
    },
    {
        "foodvisorItem": "Semi-skimmed milk",
        "foodItem": "MILK"
    },
    {
        "foodvisorItem": "Peeled kiwi",
        "foodItem": "KIWI"
    },
    {
        "foodvisorItem": "White chocolate Fudgesicle",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Swordfish",
        "foodItem": "SWORDFISH (W)"
    },
    {
        "foodvisorItem": "Tapioca",
        "foodItem": "TAPIOCA"
    },
    {
        "foodvisorItem": "Beef Jerky",
        "foodItem": "BEEF"
    },
    {
        "foodvisorItem": "Virgin olive oil",
        "foodItem": "OLIVE OIL"
    },
    {
        "foodvisorItem": "Apple crumble",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Acras",
        "foodItem": "FISH (generic)"
    },
    {
        "foodvisorItem": "Salmon carpaccio",
        "foodItem": "SALMON (W)"
    },
    {
        "foodvisorItem": "White wine",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Buckwheat flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Tigernut flour",
        "foodItem": "WHEAT FLOUR"
    },
    {
        "foodvisorItem": "Schweppes 33cL",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Peppercorn sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Fruit bar",
        "foodItem": "CORNFLAKES"
    },
    {
        "foodvisorItem": "Crispbread",
        "foodItem": "CRISPBREAD"
    },
    {
        "foodvisorItem": "Softdrink",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Sesame seeds",
        "foodItem": "SESAME SEED"
    },
    {
        "foodvisorItem": "Strawberry jam biscuit",
        "foodItem": "PLAIN CRACKERS"
    },
    {
        "foodvisorItem": "Wholegrain rice flour",
        "foodItem": "RICE FLOUR"
    },
    {
        "foodvisorItem": "Chicken risotto",
        "foodItem": "RICE"
    },
    {
        "foodvisorItem": "Chili powder",
        "foodItem": "CHILI PEPPER"
    },
    {
        "foodvisorItem": "Pistachio ice cream",
        "foodItem": "YOGURT"
    },
    {
        "foodvisorItem": "Tomme cheese",
        "foodItem": "SEMI-HARD/HARD (Generic)"
    },
    {
        "foodvisorItem": "Whole cane sugar",
        "foodItem": "BROWN SUGAR"
    },
    {
        "foodvisorItem": "Abbey draught beer",
        "foodItem": "BEER"
    },
    {
        "foodvisorItem": "Mango nectar",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Ranch dressing",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Dried dates",
        "foodItem": "DATE"
    },
    {
        "foodvisorItem": "Turkey breast",
        "foodItem": "TURKEY"
    },
    {
        "foodvisorItem": "Olive bread",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Crepe with bechamel ham cheese and mushrooms",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Champagne",
        "foodItem": "WINE"
    },
    {
        "foodvisorItem": "Leg of lamb",
        "foodItem": "LAMB"
    },
    {
        "foodvisorItem": "Currywurst",
        "foodItem": "PORK"
    },
    {
        "foodvisorItem": "Profiteroles",
        "foodItem": "BREAD PLAIN"
    },
    {
        "foodvisorItem": "Cottage Cheese",
        "foodItem": "COTTAGE CHEESE"
    },
    {
        "foodvisorItem": "Turkey cutlet",
        "foodItem": "TURKEY"
    },
    {
        "foodvisorItem": "Clementine juice",
        "foodItem": "FRUIT JUICE"
    },
    {
        "foodvisorItem": "Mint tea",
        "foodItem": "TEA"
    },
    {
        "foodvisorItem": "Cocktail sauce",
        "foodItem": "SOY SAUCE"
    },
    {
        "foodvisorItem": "Raw carrots",
        "foodItem": "CARROT"
    },
    {
        "foodvisorItem": "Split peas",
        "foodItem": "PEA (d)"
    }
]