import { Component, forwardRef, Input, Optional, Host, SkipSelf, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../base.component';
import { CultureInfo, CultureService, defaultCultureConfiguration } from 'app/common/services/culture.service';

@Component({
	selector: 'language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ["./language-selector.component.scss"]
})

export class LanguageSelectorComponent extends BaseComponent implements OnInit {
	selectedLanguageFlag = "";
	culturesInfo: CultureInfo[] = defaultCultureConfiguration.cultures;
	constructor(private _cultureService: CultureService) {
		super();
	}

	updateLang = (lang) => {
		console.log("checkLanguage ", this._cultureService.getCurrentCulture());
		this.selectedLanguageFlag = `/assets/img/flags/flag-${lang}.png`;

	}
	ngOnInit(): void {
		this.on(this._cultureService.cultureChanged.subscribe((result) => {
			console.log("cultureChanged", result);
			
			this.updateLang(result);
		}));
		this.updateLang(this._cultureService.getCurrentCulture().code);
	}
	changeLanguage(language: CultureInfo) {
		if (language.code == "it" || language.code == "en" || language.code == "es")

			this._cultureService.setCurrentCulture(language.code);
		// if (language === 'en') {
		//   this.selectedLanguageFlag = "/assets/img/flags/flag-en.png";
		// }
		// else if (language === 'es') {
		//   this.selectedLanguageFlag = "/assets/img/flags/flag-es.png";
		// }
		// else if (language === 'it') {
		//   this.selectedLanguageFlag = "/assets/img/flags/flag-it.png";
		// }
		// else if (language === 'fr') {
		//   this.selectedLanguageFlag = "/assets/img/flags/flag-fr.png";
		// }
	}
}
