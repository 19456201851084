import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from 'ngx-spinner';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { ComponentsModule } from "./common/components/components.module";
import './common/extensions-methods/array.extensions'  //or whatever path is appropriate
import './common/extensions-methods/string.extensions'  //or whatever path is appropriate
import './common/extensions-methods/form.extensions'  //or whatever path is appropriate
import './common/extensions-methods/router.extensions'  //or whatever path is appropriate
import { CultureService, CULTURE_CONFIGURATION, defaultCultureConfiguration } from "./common/services/culture.service";

import { CustomDateParserFormatter } from "./common/components/form/input-date.component";
import { CommonServicesModule } from "./common/services/common-services.module";

import { HttpService } from "./services/http.service";
import { FileProvider } from "./services/FileProvider.service";
import { NgJsonEditorModule } from 'ang-jsoneditor' 
import { RestaurantProvider } from "./services/restaurant.service";
import { ArchwizardModule } from 'angular-archwizard';
import { Title } from "@angular/platform-browser";
import { StartupModule } from "./startup.module";
import { BlockChainService } from "./services/blockchain.service";
import { ACRService } from "./services/acl.service";
import { CompanyService } from "./services/company.service";
import { ProductService } from "./services/product.service";
import { EntityService } from "./services/entity.service";
import { CertificatePageService } from "./services/certificate-page.service";
import { LandingPageModule } from "./pages/landing-page/landing-page.module";
import { SwitchDataLakeService } from "./services/switch-data-lake.service";
import { NewLandingPageModule } from "./pages/new-landing-page/new-landing-page.module";
import { SwitchDataAnalisysModule } from "./pages/switch-data-analisys/switch-data-analisys.module";
import { IngrediantService } from "./services/ingrediant.service";
import { InsideTheDishModule } from "./pages/inside-the-dish/inside-the-dish.module";
import { Gtag, GtagModule } from "angular-gtag";

var firebaseConfig = {
    apiKey: "YOUR_API_KEY", //YOUR_API_KEY
    authDomain: "YOUR_AUTH_DOMAIN", //YOUR_AUTH_DOMAIN
    databaseURL: "YOUR_DATABASE_URL", //YOUR_DATABASE_URL
    projectId: "YOUR_PROJECT_ID", //YOUR_PROJECT_ID
    storageBucket: "YOUR_STORAGE_BUCKET", //YOUR_STORAGE_BUCKET
    messagingSenderId: "YOUR_MESSAGING_SENDER_ID", //YOUR_MESSAGING_SENDER_ID
    appId: "YOUR_APP_ID", //YOUR_APP_ID
    measurementId: "YOUR_MEASUREMENT_ID" //YOUR_MEASUREMENT_ID
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        NgbModule,
        NgxSpinnerModule,
        ComponentsModule,
        CommonServicesModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'it',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: "YOUR_GOOGLE_MAP_API_KEY"
        }),
        PerfectScrollbarModule,
        // OAuthModule.forRoot(),
		StartupModule.forRoot(),
		NgJsonEditorModule,
		ArchwizardModule,
		LandingPageModule,
		NewLandingPageModule,
		SwitchDataAnalisysModule,
		InsideTheDishModule,

		// See https://www.simpleanalytics.com/glossary/google-analytics/add-google-analytics-to-angular
		GtagModule.forRoot({ trackingId: firebaseConfig.measurementId, trackPageviews: true })
    ],
    providers: [
		CultureService,
		HttpService,
		FileProvider,
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
        { provide: CULTURE_CONFIGURATION, useValue: defaultCultureConfiguration, multi: false },
        { provide: NgbDateParserFormatter, useFactory: () => { return new CustomDateParserFormatter() } },
		RestaurantProvider,
		CompanyService,
		Title,
		BlockChainService,
		ACRService,
		ProductService,
		CertificatePageService,
		EntityService,
		SwitchDataLakeService,
		IngrediantService,
		Gtag
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
