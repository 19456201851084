import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbDropdownModule, NgbModule, NgbNavModule, NgbPaginationModule, NgbProgressbarModule, NgbToastModule, NgbTooltipModule, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { DirectiveModule } from "../directives/directives.module";
import { InputDateComponent } from "./form/input-date.component";
import { InputDecimalComponent } from "./form/input-decimal.component";
import { InputIntComponent } from "./form/input-int.component";
import { InputSlideComponent } from "./form/input-slide.component";
import { InputStringComponent } from "./form/input-string.component";
import { SelectBoxComponent } from "./form/select-box.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { InputAutocompleteComponent } from "./form/input-autocomplete.component";
import { QuillModule } from 'ngx-quill';

import { InputHtmlComponent } from "./form/input-html.component";
import { DataTableComponent } from "./table/data-table.component";
import { DataTableUserAvatarCellTemplateComponent, DataTableImageCellTemplateComponent, DataTableStringCellTemplateComponent, DataTableDateCellTemplateComponent, DataTableEnumCellTemplateComponent, DataTableBooleanCellTemplateComponent, DataTableDateTimeCellTemplateComponent, DataTableTimeCellTemplateComponent, DataTableIntCellTemplateComponent, DataTableNumberCellTemplateComponent, DataTableCurrencyCellTemplateComponent, DataTableClickCellTemplateComponent, DataTableArrayCellTemplateComponent, DataTableIconImageCellTemplateComponent } from "./table/data-table-cell-template.component";
import { DataTableCellTemplateDirective } from "./table/data-table-cell-template.directive";
import { ListTableComponent } from "./table/list-table.component";
import { ModalComponent } from "./modals/modal.component";
import { ConfirmModalComponent } from "./modals/confirm-modal.component";
import { ErrorsModalComponent } from "./modals/errors-modal.component";
import { ToastsComponent } from "./toast/toasts.component";
import { InputTagsComponent } from "./form/input-tags.component";
import { TagInputModule } from 'ngx-chips';
import { SpinnerComponent } from "./spinner/spinner.component";
import { BasePageComponent } from "./pages/base-page.component";
import { LocalizedInputContainer } from "./form/localized-input-container.component";
import { FileUploadModule } from 'ng2-file-upload';


import { ListTableFilter } from "./table/list-table-filter.component";
import { InputFileComponent } from "./form/input-file.component";
import { FileUploadComponent } from "./files/FIleUpload";
import { InputJSONComponent } from "./form/input-json.component";
import { NgJsonEditorModule } from "ang-jsoneditor";
import { SecuredImage } from "./files/secured-image.component";

import { QRCodeModule } from 'angularx-qrcode';
import { PipeModule } from "app/pipes/pipe.module";
import { ClaimedNFTComponent } from "./claimed-nft/claimed-nft.component";
import { SimpleQRCodeComponent } from "./modals/simple-qrcode.component";
import { UpdatePasswordComponent } from "./modals/update-password-modal.component";
import { InputPasswordComponent } from "./form/input-password.component";
import { PageHeaderComponent } from "./pages/page-header.component";
import { ColorPickerModule } from 'ngx-color-picker';
import { InputColorComponent } from "./form/input-color.component";
import { LanguageSelectorComponent } from "./i18n/language-selector.component";
import { PageFooterComponent } from "./pages/page-footer.component";
import { RouterModule } from "@angular/router";

var toolbarOptions = [
	['bold', 'italic', 'underline', 'strike'],        // toggled buttons
	// ['blockquote', 'code-block'],
	// [{ 'header': 1 }, { 'header': 2 }],               // custom button values
	[{ 'list': 'ordered'}, { 'list': 'bullet' }],
	[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
	[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
	// [{ 'direction': 'rtl' }],                         // text direction
  
	// [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
	// [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
	// [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
	// [{ 'font': [] }],
	// [{ 'align': [] }],
  
	['clean']                                         // remove formatting button

	// , ['addplaceholder']
];

@NgModule({
    declarations: [
        InputStringComponent,
        InputDateComponent,
        InputDecimalComponent,
        InputIntComponent,
        InputSlideComponent,
        SelectBoxComponent,
        DataTableComponent,
        ListTableComponent,
        InputAutocompleteComponent,
        InputHtmlComponent,
        InputTagsComponent,
		InputPasswordComponent,
        DataTableCellTemplateDirective,
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        ModalComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
        ToastsComponent,
        SpinnerComponent,
        BasePageComponent,
        LocalizedInputContainer,
		ListTableFilter,
		InputFileComponent,
		FileUploadComponent,
		InputJSONComponent,
		SecuredImage,
		ClaimedNFTComponent,
		SimpleQRCodeComponent,
		UpdatePasswordComponent,
		PageHeaderComponent,
		InputColorComponent,
		LanguageSelectorComponent,
		PageFooterComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        DirectiveModule,
        NgSelectModule,
        NgbTypeaheadModule,
        NgbPaginationModule,
        NgbDropdownModule,
        NgbToastModule,
        TagInputModule,
        QuillModule.forRoot(
			{
				modules: {
					toolbar: toolbarOptions
				}
			}
		),
		
        NgbNavModule,
        NgbDropdownModule,
		FileUploadModule,
		NgbProgressbarModule,
		NgbTooltipModule,
		NgbModule,
		NgJsonEditorModule,
		QRCodeModule,
		PipeModule,
		ColorPickerModule,
		RouterModule
    ],
    providers: [
    ],
    exports: [
        InputStringComponent,
        InputDateComponent,
        InputDecimalComponent,
        InputIntComponent,
        InputSlideComponent,
        FormsModule,
        ReactiveFormsModule,
        SelectBoxComponent,
        InputAutocompleteComponent,
        InputHtmlComponent,
        DataTableComponent,
        ListTableComponent,
        DataTableCellTemplateDirective,
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        NgbDropdownModule,
        ModalComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
        ToastsComponent,
        InputTagsComponent,
		InputPasswordComponent,
        SpinnerComponent,
        BasePageComponent,
        TranslateModule,
        LocalizedInputContainer,
		ListTableFilter,
		InputFileComponent,
		FileUploadComponent,
		InputJSONComponent,
		SecuredImage,
		ClaimedNFTComponent,
		SimpleQRCodeComponent,
		UpdatePasswordComponent,
		PageHeaderComponent,
		InputColorComponent,
		LanguageSelectorComponent,
		PageFooterComponent
    ],
    entryComponents: [
        DataTableStringCellTemplateComponent,
        DataTableDateCellTemplateComponent,
        DataTableEnumCellTemplateComponent,
        DataTableBooleanCellTemplateComponent,
        DataTableDateTimeCellTemplateComponent,
        DataTableTimeCellTemplateComponent,
        DataTableIntCellTemplateComponent,
        DataTableNumberCellTemplateComponent,
        DataTableCurrencyCellTemplateComponent,
        DataTableClickCellTemplateComponent,
		DataTableArrayCellTemplateComponent,
		DataTableImageCellTemplateComponent,
		DataTableUserAvatarCellTemplateComponent,
		DataTableIconImageCellTemplateComponent,
        ConfirmModalComponent,
        ErrorsModalComponent,
		SecuredImage
    ],
})
export class ComponentsModule { }
