import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LandingPageDefinition } from 'app/models/landing-page.model';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { MediaFileDescription } from 'app/services/FileProvider.service';
import { ModalService } from 'app/common/services/modal.service';
import { conversionTable } from '../convertion-map';
import { dataFormService } from './pasta';
import { ToastService } from 'app/common/services/toasts.service';
import { IImage, ImageCompressService } from 'ng2-image-compress';
// import { NgxImageCompressService } from 'ngx-image-compress';



@Component({
	selector: 'inside-home',
	templateUrl: './inside-home.page.html',
	styleUrls: ['inside-home.page.scss']
})
export class InsideHome implements OnInit{
	uploader: FileUploader;
	possibileMediaTypes: any[] = ["image/png","image/jpeg"];
	@ViewChild('fileInputElement') fileInputElement: ElementRef;
	constructor(
		private _router: Router,
		private _toastService: ToastService,
		// private _imageComprex: NgxImageCompressService
		// private imgCompressService: ImageCompressService
	) {

	}

	sectionLogo;

	imageBaseUrl = environment.services.getMediaContentsBasePath + "/";

	companyLogo;

	addImage = ($event) => {
		this.fileInputElement.nativeElement.click();
		// this.goToResults(dataFormService);
	}


	goToResults = (foodvisor) => {
		if (foodvisor && foodvisor.items.length > 0) {
			let itemsFound = []
			foodvisor.items.forEach(item => {
				if (item.food && item.food.length>0 && item.food[0] && item.food[0].food_info) {
					let info = (""+item.food[0].food_info.display_name).toLowerCase().trim();
					conversionTable.forEach((element) => {
						let fE = (""+element.foodvisorItem).toLowerCase().trim();
						if (fE == info) itemsFound.push({foodItem: element.foodItem, quantity: (item.food[0].quantity)?item.food[0].quantity:0});
					})
				}
			});
			if (itemsFound && itemsFound.length > 0) {
				const queryParams = {items: JSON.stringify(itemsFound)};
				this._router.navigate(["inside-the-dish-results"], { queryParams: queryParams });
			}
			else {
				this._toastService.showDanger("Sorry! No food items found from your picture!");
			}
			
		}
	}
	

	base64toBlob = (base64Data, contentType) => {
		console.log("base64Data",base64Data);
		
		contentType = contentType || '';
		var sliceSize = 1024;
		var byteCharacters = atob(base64Data);
		var bytesLength = byteCharacters.length;
		var slicesCount = Math.ceil(bytesLength / sliceSize);
		var byteArrays = new Array(slicesCount);
	
		for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			var begin = sliceIndex * sliceSize;
			var end = Math.min(begin + sliceSize, bytesLength);
	
			var bytes = new Array(end - begin);
			for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	handleUpload(event) {
		// console.log("handleUpload", event);
		// this._ng2ImgMax.resizeImage(event.target.files[0], 100, 100).subscribe(
		//   result => {
		// 	const newImage = new File([result], result.name);
		// 	this.uploader.clearQueue();
		// 	this.uploader.addToQueue([newImage]);
		// 	this.uploader.uploadAll();
		//   },
		//   error => console.log(error)
		// );
		let fileList: FileList;

   		// let images: Array<IImage> = [];
    
		ImageCompressService.filesToCompressedImageSource(event.target.files).then(observableImages => {
		observableImages.subscribe((image) => {
			// images.push(image);
			console.log("image",image);
			let b: Blob = this.base64toBlob(image.compressedImage.imageDataUrl.replace("data:"+image.type+";base64,",""), image.type);
			console.log("bynary",b);
			
			const newImage = new File([b], image.fileName);
			console.log("newImage",newImage);
			
			this.uploader.clearQueue();
			this.uploader.addToQueue([newImage]);
			this.uploader.uploadAll();
		}, (error) => {
			console.log("Error while converting");
		}, () => {

					// this.processedImages = images;      
					// this.showTitle = true;          
		});
		});

		// // or you can pass File[] 
		// let files = Array.from(event.target.files);

		// ImageCompressService.filesArrayToCompressedImageSource(files).then(observableImages => {
		// observableImages.subscribe((image) => {
		// 	images.push(image);
		// }, (error) => {
		// 	console.log("Error while converting");
		// }, () => {
		// 			this.processedImages = images;      
		// 			this.showTitle = true;          
		// });
		// });
	

	
	}
	
	ngOnInit() {
		this.initFileUploader();		
		

		this.uploader.onAfterAddingFile = (item: FileItem) => {
			console.log("onAfterAddingFile",item);
			console.log(this.uploader.queue)
			if (this.possibileMediaTypes.length==0 || (this.possibileMediaTypes.length > 0 && this.possibileMediaTypes.indexOf(item.file.type)>=0)) {
				this.item = item;
				item.upload();
			}
			else {
				this._toastService.showDanger("the file you provided is not valid")
			}
			
		}

		// this.uploader.onWhenAddingFileFailed = (item, filter) => {
		// 	console.log("onWhenAddingFileFailed",item);
		// 	switch (filter.name) {
		// 	  case 'fileSize':
		// 		// message = 'Attenzione ! \nIl file \"' + item.name + '\" ha una dimensione superiore a quella consentita (2 MB)';
		// 		this._toastService.showDanger("Please, use a photo smaller than 2MB")
		// 		break;
		// 	  default:
		// 		// message = 'Si è verificato un errorError trying to upload file '+item.name;
		// 		break;
		// 	}
		
		// 	// alert(message);

		// 	// console.log("error", filter);
		// 	// console.log("error item", item);
			
		//   };

		this.uploader.onBeforeUploadItem = (item) => {
			item.withCredentials = false;
		}
		this.uploader.onBuildItemForm = (item: FileItem, form: any) => {
			console.log("onBuildItemForm", item);
			
			form.append('filename' , item.file.name);
			if (item.file.type)
				form.append('mimetype' , item.file.type);
			else if (item.file.name.endsWith(".glb"))
				form.append('mimetype' , "model/gltf-binary");
			else 
				form.append('mimetype' , "application/octet-stream");
			console.log("form for file", form);
			
		};
	  
		this.uploader.onSuccessItem = (item: FileItem, response: any, status: any, headers: any) => {
			const reader = new FileReader();
			
			reader.addEventListener("load", () => {
				let result = new Object();
				Object.assign(result,JSON.parse(response));
				this.goToResults(result);
				// result.mediaUrl = reader.result;
				// this.currentFile = result;
				// this.mediaId = result.id;
				// this.updateEnabled = false;
				this.initFileUploader();
				// this.onMediaUpdate.emit(result);
			}, false);
			reader.readAsDataURL(item._file);
		}
		

	
	}

	initFileUploader() {
		if (!this.uploader) {
			this.uploader = new FileUploader({
				itemAlias: "image",
				isHTML5: true,
				maxFileSize: 2*1024*1024,
				authToken: "Api-Key xMCogPgb.iFTdyw1m3WOC5vONxQT31829GfvlIUsS"
			});
		}
		// this.uploader.options.url= environment.services.mediaContentsBasePath;
		this.uploader.options.url=  "https://api-gateway-switchproject.posti.world/foodvisor/api/1.0/en/analysis/";
		
		this.uploader.options.method=  "POST";
	}
	item;
	currentFile: MediaFileDescription;
	
}