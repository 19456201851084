import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ModalService } from 'app/common/services/modal.service';
import { ViewportScroller } from '@angular/common';
import { CompanyService } from 'app/services/company.service';
import { TranslateService } from '@ngx-translate/core';
import { LandingPageService } from 'app/services/landing-page.service';
import { ModalOptions } from 'app/common/models/models';
import { ItemDetailModalComponent } from '../item-details/item-detail-modal/item-detail-modal.component';
import { Gtag } from 'angular-gtag';


@Component({
	selector: 'food-items-page',
	templateUrl: './food-items.page.html',
	styleUrls: ['food-items.page.scss']
})
export class FoodItemsPage implements OnInit, AfterViewInit{
	pageLoaded = false;

	constructor(
		private _modalService: ModalService,
		private gtag: Gtag
	) {

	}

	
	ngAfterViewInit(): void {
		
	}

	
	ngOnInit() {
		this.pageLoaded = true;

		this.gtag.event('food-items-page', {
			event_category: "Page view",
			'app_name': "switch-food-explorer",
			'screen_name': 'food-items-page'
		});
	}

	// onItemSelected(item) {
	// 	console.log("selected ", item);
	// 	const queryParams = {
	// 		item: (item.id)? JSON.stringify(item): JSON.stringify({ id: item })
	// 	};
	// 	this._router.navigate(["item-detail"], { queryParams: queryParams });
	
		
	// }
	
	onItemSelected = (item) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'xl',
			callback: (res) => {
				
			}
		}
		let data = {
			item: item
		}
		this._modalService.show(ItemDetailModalComponent, data, modalOption);
	}
	

}