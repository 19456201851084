import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { iconLink, iconUserAvatar } from 'app/common/utils/icons.utils';
import { environment } from 'environments/environment';

@Component({
	selector: 'page-footer',
	templateUrl: './page-footer.component.html',
	encapsulation: ViewEncapsulation.None
})
export class PageFooterComponent implements OnInit {
	logoUrl = environment.domainConfig.companyLogo;
	portalName = environment.domainConfig.portalName;
	iconUserAvatar = iconUserAvatar;
	iconLink = iconLink;
	constructor() {}

	ngOnInit(): void {
		
	}

	
}
