import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LandingPageDefinition } from 'app/models/landing-page.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Group, IngrediantService } from 'app/services/ingrediant.service';
import { ReceiptItem } from 'app/pages/switch-data-analisys/receipt-creation/receipt-creation.component';



@Component({
	selector: 'inside-dish',
	templateUrl: './inside-dish.page.html',
	styleUrls: ['inside-dish.page.scss']
})
export class InsideDish implements OnInit{

	baseColors = ["#fab72680", "#d8223980", "#1e8ca880", "#dd8c3380", "#50adea80"];
	

	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	topLeft = [
		{top: "40vh", left: "25vw"},
		{top: "23vh", left: "5vw"},
		{top: "58vh", left: "56vw"},
		{top: "60vh", left: "10vw"},
		{top: "10vh", left: "10vw"},
		{top: "40vh", left: "52vw"},
		{top: "20vh", left: "20vw"},
		{top: "25vh", left: "55vw"},
		{top: "40vh", left: "40vw"},
		{top: "50vh", left: "50vw"}
	]
	form: FormGroup;
	bgImageUrl = null;
	constructor(
		private _translate: TranslateService,
		private _router: Router,
		private _fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private _ingrediantService: IngrediantService
	) {

	}

	sectionLogo;

	imageBaseUrl = environment.services.getMediaContentsBasePath + "/";

	companyLogo;

	data = [];
	ingrediants: ReceiptItem[] = [];
	servingNumber = 1;
	totalQty = 0;
	maxQty = 0;
	private recursiveGetFoodItems = (group: Group) => {
		if (group.items && group.items.length > 0) {
			group.items.forEach((item) => {
				let viewItem: ReceiptItem = new ReceiptItem();
				Object.assign(viewItem, item);
				viewItem.label = item.id;
				viewItem.quantity = 100;
				viewItem.measurementUnit = (item.unitsWaterfootprint == "liters water/kg")? "g": "ml";
				this.data.push(viewItem);
			});
		}
		if (group.subgroups && group.subgroups.length > 0) {
			group.subgroups.forEach((g) => {
				this.recursiveGetFoodItems(g);
			})
		}
	}
	goBack = () => {
		const queryParams = {};
		this._router.navigate(["inside-the-dish-home"], { queryParams: queryParams });
	}
	ngOnInit() {
		this.activatedRoute.queryParams.subscribe((params) => {
			console.log("query params: ", params);
			if (params["items"]) {
				let items = JSON.parse(params["items"]);
				console.log("items: ", items);
				this._ingrediantService.getAll().subscribe((result) => {
					let _ingrediants = [];
					result.forEach((g) => {
						this.recursiveGetFoodItems(g);
					})
					items.forEach(food => {
						let found = null;
						this.data.forEach((el) => {
							if ((""+food.foodItem).toLowerCase() == (""+el.id).toLowerCase()) {
								let viewItem: ReceiptItem = new ReceiptItem();
								Object.assign(viewItem, el);
								viewItem.label = el.id;
								viewItem.quantity = food.quantity;
								this.totalQty += food.quantity;
								if (this.maxQty < food.quantity) this.maxQty = food.quantity;
								viewItem.measurementUnit = (el.unitsWaterfootprint == "liters water/kg")? "g": "ml";
								_ingrediants.push(viewItem);
							}
						})
					});

					console.log("total qty " + this.totalQty);
					let maxRadius = 0;
					_ingrediants = _ingrediants.map((v, index) => {
						(<any>v).radius = Math.round((Math.round((100/this.maxQty)*v.quantity)+130)/5);
						console.log("(<any>v).radius " + (<any>v).radius);
						if ((<any>v).radius > maxRadius) maxRadius = (<any>v).radius;

						(<any>v).color = this.baseColors[index%this.baseColors.length];
						// (<any>v).top = (Math.round(Math.random()*50)+30)+"vh";
						// (<any>v).left = (Math.round(Math.random()*30)+20)+"vw";
						// (<any>v).top = this.topLeft[index%this.topLeft.length].top;
						// (<any>v).left = this.topLeft[index%this.topLeft.length].left;
						return v;
					})
					_ingrediants =_ingrediants.sort((a,b) => {return b.quantity - a.quantity;})
					this.ingrediants = _ingrediants.map((v, index) => {
						(<any>v).top = this.topLeft[index%this.topLeft.length].top;
						(<any>v).left = this.topLeft[index%this.topLeft.length].left;
						(<any>v).fontSize = Math.round((0.5 + ((<any>v).radius)/maxRadius)*10)/10;
						(<any>v).measureSize = Math.round((0.1+ ((<any>v).radius)/maxRadius)*10)/10;
						
						return v;
					})

					console.log("food items found: ", this.ingrediants);
				});
			}
		})
		
		
		
		
		// this.form = this._fb.group({
		// 	dish: [null]
		// })

		// this.form.valueChanges.subscribe((res) => {
		// 	console.log(res);
		// 	if (res && res.dish) {
		// 		this.bgImageUrl = environment.services.mediaContentsBasePath + "/" +res.dish;
		// 		this.bgStyle = {
		// 			"background-image": "url("+this.bgImageUrl+")"
		// 		}
		// 	}
				
		// 	else {
		// 		this.bgImageUrl = null;
		// 		this.bgStyle = {
		
		// 		}
		// 	}
		// })
	}
	removeImage = () => {
		this.form.patchValue({dish:null});
	}
	bgStyle = {
		
	}

	get carbonFootprint() {
		let value = 0;
		this.ingrediants.forEach((e) => {
			value += e.carbonFootprint * e.quantity / 1000;
		})
		
		return Math.round((value/this.servingNumber)*1000)/1000;
	}
	get waterFootprint() {
		let value = 0;
		this.ingrediants.forEach((e) => {
			value += e.waterFootprint * e.quantity / 1000;
		})
		return Math.round(value/this.servingNumber);
	}
	get fishQualitySustainability() {
		return "A";
	}

	get energy () {
		let value = 0;
		this.ingrediants.forEach((e) => {
			value += e.energy * e.quantity / 100;
		})
		return Math.round((value/this.servingNumber)*100)/100;
		
	}

	public swiperPaginationConfig: SwiperConfigInterface = {
		loop: true,
		pagination: {
		  el: '.swiper-pagination',
		  clickable: true,
		},
	  };
}