import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { UnauthorizedModule } from '../unautorized-page/unautorized.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InsideHome } from './inside-home/inside-home.page';
import { InsideDish } from './inside-dish/inside-dish.page';
import { FileUploadModule } from 'ng2-file-upload';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
// import { NgxImageCompressService} from 'ngx-image-compress';
import { ImageCompressService,ResizeOptions,ImageUtilityService } from 'ng2-image-compress';

export const InsideTheDishRoutes: Routes = [
    { path: 'inside-the-dish-home', component: InsideHome, canActivate: []},
	{ path: 'inside-the-dish-results', component: InsideDish, canActivate: []}
];
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
	direction: 'horizontal',
	slidesPerView: 'auto'
  };

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(InsideTheDishRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		UnauthorizedModule,
		PipeModule,
		NgbModule,
		NgApexchartsModule,
		FileUploadModule,
		SwiperModule,
		// NgxImageCaptureModule
    ],
    exports: [
		InsideHome,
		InsideDish
	],
    declarations: [
		InsideHome,
		InsideDish
    ],
	providers: [
		// NgxImageCompressService,
		ImageCompressService,ResizeOptions,
		{
		  provide: SWIPER_CONFIG,
		  useValue: DEFAULT_SWIPER_CONFIG
		}
	],
	entryComponents: [
		InsideHome,
		InsideDish
	]
})
export class InsideTheDishModule { }
